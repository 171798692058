import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from 'app/api';

export type DiplomasState = {
  loading: boolean;
  data?: api.types.Diploma[];
  isDiplomasFetched?: boolean;
};

export const initialState: DiplomasState = {
  loading: false,
  isDiplomasFetched: false,
};

export const fetchTemplateDiplomasThunk = createAsyncThunk(
  'diplomas/fetchTemplateDiplomas',
  (
    args: {
      access_token: string;
      templateId: string;
      params: api.types.DiplomasByTempateIdParams;
    },
    { rejectWithValue }
  ) =>
    api
      .getDiplomasByTemplateId(args.access_token, args.templateId, args.params)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const deleteTemplateDiplomasThunk = createAsyncThunk(
  'diplomas/deleteTemplateDiplomas',
  (args: { access_token: string; templateId: string }, { rejectWithValue }) =>
    api
      .deleteDiplomasByTemplateId(args.access_token, args.templateId)
      .catch(rejectWithValue)
);

export const deleteDiplomaThunk = createAsyncThunk(
  'diplomas/delete',
  (args: { id: string; access_token: string }, { rejectWithValue }) =>
    api
      .deleteDiplomaById(args.access_token, args.id)
      .then(() => args.id)
      .catch(rejectWithValue)
);

const diplomasSlice = createSlice({
  name: 'diplomas',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsDiplomasFetched: (state, action: PayloadAction<boolean>) => {
      state.isDiplomasFetched = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchTemplateDiplomasThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchTemplateDiplomasThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTemplateDiplomasThunk.fulfilled, (state) => {
        state.data = [];
      })
      .addCase(deleteDiplomaThunk.fulfilled, (state, action) => {
        state.data = state.data?.filter((d) => d.id !== action.payload);
      }),
});

export default diplomasSlice;
