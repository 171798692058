import { ComponentProps } from 'react';
import { IconButton, ListSubheader } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import styled from 'styled-components';

type Props = ComponentProps<typeof Root> & {
  title?: string;
  count?: number;
  onRefresh?: () => any;
  disabled?: boolean;
};

export default function RefreshableSubheader({
  title,
  count,
  onRefresh,
  disabled,
  ...restProps
}: Props) {
  return (
    <Root {...restProps}>
      {`${title}${typeof count !== 'undefined' ? ` (${count})` : ''}`}
      {onRefresh && (
        <IconButton onClick={onRefresh} disabled={disabled} edge="end">
          <RefreshIcon />
        </IconButton>
      )}
    </Root>
  );
}

const Root = styled(ListSubheader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: static;
`;
