import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import * as api from 'app/api';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { RootState } from '.';

export type AuthState = {
  token?: api.types.UserToken;
};

export const initialState: AuthState = {};

export const accessTokenSelector = createSelector(
  (state: RootState) => state.auth.token,
  (token) => token?.access_token
);

export const authCompleteThunk = createAsyncThunk(
  'auth/complete',
  (code: string, { rejectWithValue }) =>
    api
      .createUserToken(code)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const authRefreshThunk = createAsyncThunk(
  'auth/refresh',
  (refresh_token: string, { rejectWithValue }) =>
    api
      .refreshUserToken(refresh_token)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(authCompleteThunk.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      .addCase(authRefreshThunk.fulfilled, (state, action) => {
        state.token = action.payload;
      }),
});

export const persistedReducer = persistReducer(
  {
    key: 'auth',
    whitelist: ['token'],
    storage,
  },
  authSlice.reducer
);

export default authSlice;
