import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  List,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import {
  ComponentProps,
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  useCallback,
} from 'react';
import * as api from 'app/api';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { accessTokenSelector } from 'app/store/auth';
import debounce from 'lodash/debounce';

import { useEvents } from '../hooks/useEvents';
import { DEBOUNCE_DEFAULT } from '../constants/debounce';
import { fetchOrganizationsThunk } from '../store/organization';
import { ALL_ORGANIZATIONS_OPTION } from '../constants/organization';
import { useEventSearchStyles } from '../hooks/useEventSearchStyles';

import EventListItem from './EventListItem';
import RefreshableSubheader from './RefreshableSubheader';

type Props = Omit<ComponentProps<typeof Dialog>, 'onSubmit'> & {
  onSubmit?: (id: api.types.Event['id']) => any;
};

const REQUEST_LIMIT = 5;

export default function EventPickerDialog({
  onSubmit,
  onClose,
  ...restProps
}: Props) {
  const classes = useEventSearchStyles();
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const { data: user } = useSelector((state: RootState) => state.user);
  const accessToken = useSelector(accessTokenSelector)!;
  const { getEvents, clearEvents, events: data, loading, count } = useEvents();
  const {
    data: orgData,
    adminOfOrganizations,
    loading: orgLoading,
    status: orgFetchStatus,
  } = useSelector((state: RootState) => state.organizations);
  const [selectedOrganization, setSelectedOrganization] = useState('null');
  const pagesCount = useMemo(() => {
    if (!count) return 0;
    return Math.ceil(count / REQUEST_LIMIT);
  }, [count]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearch(e.target.value);
      setPage(1);
      if (!e.target.value && selectedOrganization === 'null') clearEvents();
    },
    [clearEvents, selectedOrganization]
  );

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, DEBOUNCE_DEFAULT);
  }, [handleSearch]);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  }, [debouncedResults]);

  const handleSetPage = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSelectedOrganization(event.target.value as string);
    setPage(1);
    if (!search && event.target.value === 'null') clearEvents();
  };

  const userId = user?.id;
  const organizations = orgData
    ? [ALL_ORGANIZATIONS_OPTION, ...orgData]
    : [ALL_ORGANIZATIONS_OPTION];

  useEffect(() => {
    const request = async () => {
      if (userId) {
        !orgData &&
          (await dispatch(
            fetchOrganizationsThunk({
              accessToken,
              user_id: userId,
            })
          ));

        if (!search && selectedOrganization === 'null') return;
        if (orgFetchStatus === 'fetched') {
          const ids = search !== '' && !isNaN(Number(search)) && search;
          await getEvents(accessToken, {
            limit: REQUEST_LIMIT,
            offset: (page - 1) * REQUEST_LIMIT,
            ...(search && !ids && { search }),
            ...(ids && { ids }),
            ordering: '-start, -updated_at',
            ...(selectedOrganization &&
              selectedOrganization !== 'null' && {
                organization: selectedOrganization,
              }),
          });
        }
      }
    };

    request();
  }, [
    accessToken,
    dispatch,
    userId,
    page,
    search,
    selectedOrganization,
    orgData,
    getEvents,
    orgFetchStatus,
  ]);

  const isLoading = loading || orgLoading;
  return (
    <Dialog onClose={onClose} fullWidth {...restProps}>
      <DialogTitle>Выберите мероприятие</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="Введите название мероприятия или его ID"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={debouncedResults}
        />
        <FormControl
          className={classes.formControl}
          variant="outlined"
          fullWidth
        >
          <InputLabel id="select-org-label">Выберите организацию</InputLabel>
          <Select
            labelId="select-org-label"
            id="demo-simple-select"
            fullWidth
            value={orgData ? selectedOrganization : 'null'}
            label="Выберите организацию"
            onChange={handleChange}
            className={classes.selectEmpty}
            disabled={!orgData?.length}
          >
            {organizations.map((org) => (
              <MenuItem value={org.id} key={org.id + 'org'}>
                {org.short_name || org.full_name || 'Организация'}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <List
          subheader={<RefreshableSubheader title="Мероприятия" count={count} />}
        >
          {data?.map((event) => (
            <EventListItem
              onClick={() => {
                if (!adminOfOrganizations[event?.organization?.id]) return;
                onSubmit?.(event.id);
              }}
              key={event.id}
              button
              event={event}
              isUserAdmin={adminOfOrganizations[event?.organization?.id]}
              className={isLoading ? classes.loading : ''}
            />
          ))}
        </List>
        {!!pagesCount && (
          <Box
            mt={2}
            mb={4}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Pagination
              count={pagesCount}
              page={page}
              onChange={handleSetPage}
            />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
