import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useEventSearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginBottom: theme.spacing(1),
      minWidth: 120,
      marginTop: theme.spacing(2),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    loading: {
      filter: 'blur(2px)',
    },
  })
);
