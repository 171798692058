import Container from '@material-ui/core/Container';
import { ComponentProps, ComponentType } from 'react';
import styled from 'styled-components';

const Main = styled(Container).attrs({
  component: 'main',
})`
  margin: ${(props) => props.theme.spacing(2)}px auto;
` as ComponentType<ComponentProps<'main'>>;

export default Main;
