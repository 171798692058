import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { PersistPartial } from 'redux-persist/es/persistReducer';

import { AuthState, persistedReducer as authReducer } from './auth';
import eventSlice, { EventState } from './event';
import templateSlice, { TemplateState } from './template';
import userSlice, { UserState } from './user';
import renderSlice, { RenderState } from './render';
import diplomasSlice, { DiplomasState } from './diplomas';
import organizationsSlice, { OrganizationState } from './organization';

export type RootState = {
  auth: AuthState & PersistPartial;
  user: UserState;
  event: EventState;
  template: TemplateState;
  render: RenderState;
  diplomas: DiplomasState;
  organizations: OrganizationState;
};

export type AppDispatch = ReturnType<typeof createStore>['store']['dispatch'];

export type PreloadedState = RootState | undefined;

export function rootReducer() {
  return combineReducers<RootState>({
    auth: authReducer,
    user: userSlice.reducer,
    event: eventSlice.reducer,
    template: templateSlice.reducer,
    render: renderSlice.reducer as any,
    diplomas: diplomasSlice.reducer,
    organizations: organizationsSlice.reducer,
  });
}

export function createStore(preloadedState: PreloadedState = undefined) {
  const store = configureStore({
    reducer: rootReducer(),
    preloadedState,
    middleware: getDefaultMiddleware({
      serializableCheck: false,
    }),
  });
  const persistor = persistStore(store);
  return { store, persistor };
}
