export const toKey = (root: string) => (prop: string) => `${root}.${prop}`;
export const TeamFields = [
  'id',
  'persons_count',
  'title',
  'owner',
  'event',
  'project',
  ...[
    'id',
    'email',
    'first_name',
    'last_name',
    'middle_name',
    'address',
    'birthday',
    'phone',
    'about',
    'country',
    'federal_district',
    'region_with_type',
    'city_type_full',
    'city',
  ].map(toKey('owner_user')),
  'users',
];
export const EducationFields = [
  'id',
  'country',
  'federal_district',
  'region_with_type',
  'city_type_full',
  'city',
  'category',
  'grade',
  'grade_with_label',
  'title',
  'address',
  'ogrn',
  'inn',
  'oktmo',
  'okato',
  'start',
  'end',
  'person',
];

export const OrganizationFields = [
  'id',
  'country',
  'federal_district',
  'region_with_type',
  'city',
  'description',
  'full_name',
  'inn',
  'org_type',
  'short_name',
  'contact_email',
  'contact_phone',
  'contact_first_name',
  'contact_last_name',
  'contact_middle_name',
  'created_at',
  'updated_at',
];

export const diplomaTeamFields = [
  ...TeamFields.map(toKey('team')),
  ...[
    'id',
    'score',
    'comment',
    'created_at',
    ...['id', 'name'].map(toKey('role')),
    ...[
      'id',
      'title',
      'alt_title',
      'description',
      'url',
      'format',
      'country',
      'federal_district',
      'region_with_type',
      'city',
      'start',
      'end',
      'created_at',
      'updated_at',
      ...OrganizationFields.map(toKey('organization')),
    ].map(toKey('event')),
  ].map(toKey('achievement')),
];
export const fields: string[] = [
  ...[
    'id',
    'email',
    'first_name',
    'last_name',
    'middle_name',
    'address',
    'birthday',
    'phone',
    'about',
    'country',
    'federal_district',
    'region_with_type',
    'city_type_full',
    'city',
  ].map(toKey('user')),
  ...EducationFields.map(toKey('education')),
  ...[
    'id',
    'person',
    'score',
    'comment',
    'created_at',
    ...['id', 'name'].map(toKey('role')),
    ...[
      'id',
      'title',
      'alt_title',
      'description',
      'url',
      'format',
      'country',
      'federal_district',
      'region_with_type',
      'city',
      'start',
      'end',
      'created_at',
      'updated_at',
      ...OrganizationFields.map(toKey('organization')),
    ].map(toKey('event')),
  ].map(toKey('achievement')),
];

export const fonts: Record<
  string,
  { name: string; styleName: string; url?: string }
> = {
  roboto: {
    name: 'Roboto',
    styleName: 'roboto',
  },
  chevin: {
    name: 'Chevin',
    styleName: 'chevin',
  },
  robotomono: {
    name: 'Roboto Mono',
    styleName: 'robotomono',
    url:
      'https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap',
  },
  arial: {
    name: 'Arial',
    styleName: 'arial',
  },
  opensans: {
    name: 'Open Sans',
    styleName: 'opensans',
    url:
      'https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
  },
  montserrat: {
    name: 'Montserrat',
    styleName: 'montserrat',
    url:
      'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&display=swap',
  },
  ptsans: {
    name: 'PT Sans',
    styleName: 'ptsans',
    url:
      'https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap',
  },
  ubuntu: {
    name: 'Ubuntu',
    styleName: 'ubuntu',
    url:
      'https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400;1,700&display=swap',
  },
  commissioner: {
    name: 'Commissioner',
    styleName: 'commissioner',
    url:
      'https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap',
  },
};

export const conditionalFields = {
  'user.sex': [
    { value: 'm', title: 'М' },
    { value: 'w', title: 'Ж' },
  ],
};
