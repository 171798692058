import {
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { RouteComponentProps, Link } from 'react-router-dom';
import Header from 'app/components/Header';
import { compile } from 'path-to-regexp';
import { Routes } from 'app/router/Routes';
import { ArrowBack } from '@material-ui/icons';
import Main from 'app/components/Main';
import { accessTokenSelector } from 'app/store/auth';
import * as api from 'app/api';
import qs from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { AppDispatch, RootState } from 'app/store';
import { useSelector, useDispatch } from 'react-redux';
import diplomasSlice, { fetchTemplateDiplomasThunk } from 'app/store/diplomas';
import { useUnmount } from 'react-use';
import RefreshableSubheader from 'app/components/RefreshableSubheader';
import moment from 'moment';
import { Pagination } from '@material-ui/lab';
import templateSlice, { createDiplomasTaskThunk } from 'app/store/template';
import DiplomasGroupTaskDialog from 'app/components/DiplomasGroupTaskDialog';

import { DiplomaErrorType } from '../api/types';

type Props = RouteComponentProps<{ id: string; templateId: string }>;

const PER_PAGE = 10;
const AFTER_CREATE_INTERVAL = 15000; // 15 секунд

export default function CreateDiplomasPage({ match, history }: Props) {
  const isDiplomasFetched = useSelector(
    (state: RootState) => state.diplomas.isDiplomasFetched
  );
  const templateState = useSelector((state: RootState) => state.template);
  const accessToken = useSelector(accessTokenSelector)!;
  const dispatch = useDispatch<AppDispatch>();
  const { data } = useSelector((state: RootState) => state.diplomas);
  const queryParams = useMemo(() => qs.parse(history.location.search), [
    history.location.search,
  ]);
  const page = Number(queryParams['page']) || 1;
  const pages = useMemo(() => Math.ceil((data?.length || 0) / PER_PAGE), [
    data,
  ]);
  const [task, setTask] = useState<api.types.DiplomasTask>();
  const params = useMemo<api.types.DiplomasByTempateIdParams>(() => {
    return {
      success: false,
      task_id: match.params.id,
    };
  }, [match.params.id]);

  useEffect(() => {
    api.getDiplomasTask(accessToken, match.params.id).then((res) => {
      setTask(res.data);
    });
  }, [accessToken, match.params.id]);

  useEffect(() => {
    if (isDiplomasFetched) {
      setTimeout(() => {
        dispatch(
          fetchTemplateDiplomasThunk({
            access_token: accessToken,
            templateId: match.params.templateId,
            params,
          })
        );
      }, AFTER_CREATE_INTERVAL);
    }
  }, [
    accessToken,
    dispatch,
    isDiplomasFetched,
    match.params.templateId,
    params,
  ]);
  useEffect(() => {
    if (templateState.task?.id) {
      history.push({
        pathname: `/templates/${match.params.templateId}/create_diplomas/${templateState.task?.id}`,
      });
    }
  }, [history, match.params.templateId, templateState.task?.id]);

  const createUnloaded = () => {
    if (!data?.length) return;
    const ids: number[] = data.map((diploma) => {
      return diploma.achievement_id;
    });
    setTask(undefined);
    dispatch(diplomasSlice.actions.reset());
    dispatch(templateSlice.actions.reset());
    dispatch(
      createDiplomasTaskThunk({
        payload: {
          template_id: match.params.templateId,
          only_achievements: ids,
        },
        access_token: accessToken,
      })
    );
  };

  const indexById = useMemo(() => {
    const ret: Record<string, number> = {};
    [...(data || [])].reverse().forEach((diploma, index) => {
      ret[diploma.id] = index + 1;
    });
    return ret;
  }, [data]);

  useUnmount(() => {
    dispatch(diplomasSlice.actions.reset());
    dispatch(templateSlice.actions.reset());
  });
  return (
    <>
      <Header position="static" />
      <Main>
        <Button
          component={Link}
          to={compile(Routes.TEMPLATE_DIPLOMAS)({
            templateId: match.params.templateId,
          })}
          variant="outlined"
          startIcon={<ArrowBack />}
        >
          Дипломы
        </Button>
        <Box paddingY={2}>
          <Typography variant="h5">Создание дипломов</Typography>
        </Box>
        {task && (
          <DiplomasGroupTaskDialog
            open
            task={task}
            onPoll={() => {
              api.getDiplomasTask(accessToken, match.params.id).then((res) => {
                setTask(res.data);
              });
            }}
            onClose={() => setTask(undefined)}
          />
        )}
        {isDiplomasFetched && !data ? (
          <Box
            paddingY={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <CircularProgress color="secondary" />
            <div>Последние шаги...</div>
          </Box>
        ) : null}
        {data && data?.length ? (
          <Box paddingY={2}>
            <Button onClick={createUnloaded} color="primary" size="small">
              Пересоздать
            </Button>
          </Box>
        ) : null}
        {data && !data.length ? (
          <p style={{ fontSize: '16px' }}>Все дипломы загружены</p>
        ) : null}
        {data && data?.length ? (
          <List
            subheader={
              <RefreshableSubheader
                title="Незагруженные дипломы"
                count={data?.length}
              />
            }
          >
            {data
              ?.slice((page - 1) * PER_PAGE, page * PER_PAGE)
              .map((diploma) => (
                <ListItem component="div" key={diploma.id}>
                  <ListItemText
                    primary={`${indexById[diploma.id]}) ${diploma.id}`}
                    secondary={
                      <Box>
                        {diploma?.error?.type === DiplomaErrorType.OVERFLOW && (
                          <Typography color="error">
                            ОШИБКА: ФИО участников команды не входит в
                            отведенные размеры блока
                          </Typography>
                        )}
                        {diploma?.error?.type ===
                          DiplomaErrorType.MISSING_FIELD && (
                          <Typography color="error">
                            ОШИБКА: У поля "{diploma.error.field}" отсутствует
                            значение
                          </Typography>
                        )}
                        <Typography>
                          Talent ID: {diploma.user_id || 'не указано'}
                        </Typography>
                        <Typography>
                          Team ID: {diploma.team_id || 'не указано'}
                        </Typography>
                        <Typography>
                          Достижение: {diploma.achievement_id}
                        </Typography>
                        <Typography>Task: {diploma.task_id}</Typography>
                        <Typography>
                          Дата:{' '}
                          {moment(diploma.created_at).format(
                            'DD.MM.YYYY HH:mm:ss'
                          )}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
          </List>
        ) : null}
        {pages && pages > 1 ? (
          <Pagination
            count={pages}
            page={page}
            onChange={(_, page) =>
              history.push({
                ...history.location,
                search: qs.stringify({ ...queryParams, page }),
              })
            }
          />
        ) : null}
      </Main>
    </>
  );
}
