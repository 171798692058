import { ComponentProps, useEffect } from 'react';
import {
  Redirect,
  Route,
  Router as ReactRouter,
  Switch,
} from 'react-router-dom';
import NotFoundPage from 'app/pages/NotFoundPage';
import AuthCompletePage from 'app/pages/AuthCompletePage';
import LogoutPage from 'app/pages/LogoutPage';
import LoginPage from 'app/pages/LoginPage';
import { useDispatch, useSelector } from 'react-redux';
import { accessTokenSelector } from 'app/store/auth';
import { fetchUserThunk } from 'app/store/user';
import { AppDispatch } from 'app/store';
import EventsPage from 'app/pages/EventsPage';
import EventPage from 'app/pages/EventPage';
import TemplatePage from 'app/pages/TemplatePage';
import RenderPage from 'app/pages/RenderPage';
import CreateDiplomasPage from 'app/pages/CreateDiplomasPage';
import TemplateDiplomasPage from 'app/pages/TemplateDiplomasPage';

import { Routes } from './Routes';

type Props = ComponentProps<typeof ReactRouter>;

export default function Router(props: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(accessTokenSelector);
  useEffect(() => {
    if (accessToken) {
      dispatch(fetchUserThunk(accessToken));
    }
  }, [accessToken, dispatch]);
  return (
    <ReactRouter {...props}>
      <Switch>
        <Route exact path={Routes.LOGIN} component={LoginPage} />
        <Route exact path={Routes.LOGOUT} component={LogoutPage} />
        <Route exact path={Routes.AUTH_COMPLETE} component={AuthCompletePage} />
        <Route exact path={Routes.RENDER} component={RenderPage} />
        {!accessToken && <Redirect to={Routes.LOGIN} />}
        <Route exact path={Routes.INDEX} component={EventsPage} />
        <Route exact path={Routes.EVENT} component={EventPage} />
        <Route exact path={Routes.TEMPLATE} component={TemplatePage} />
        <Route
          exact
          path={Routes.TEMPLATE_DIPLOMAS}
          component={TemplateDiplomasPage}
        />
        <Route
          exact
          path={Routes.CREATE_DIPLOMAS}
          component={CreateDiplomasPage}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </ReactRouter>
  );
}
