export enum Routes {
  INDEX = '/',
  TEMPLATES = '/templates',
  TEMPLATE = '/templates/:id',
  TEMPLATE_DIPLOMAS = '/templates/:templateId/diplomas',
  RENDER = '/render',
  LOGIN = '/login',
  LOGOUT = '/logout',
  AUTH_COMPLETE = '/auth/complete',
  EVENT = '/events/:id',
  CREATE_DIPLOMAS = '/templates/:templateId/create_diplomas/:id',
}
