import {
  deleteDiplomaThunk,
  deleteTemplateDiplomasThunk,
} from 'app/store/diplomas';
import * as api from 'app/api';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/store';
import { accessTokenSelector } from 'app/store/auth';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

type ComponentProps = {
  setDelete: React.Dispatch<React.SetStateAction<boolean>>;
  templateId: string;
  isDelete: boolean;
  deleteId?: api.types.Diploma['id'];
  setDeleteId: React.Dispatch<React.SetStateAction<string | undefined>>;
  deleteAndCreateData: api.types.IDeleteAndCreateData | null;
  setDeleteAndCreateData: React.Dispatch<
    React.SetStateAction<api.types.IDeleteAndCreateData | null>
  >;
  setGroupTask: React.Dispatch<
    React.SetStateAction<api.types.DiplomasTask | undefined>
  >;
  refreshData: api.types.IRefreshData | null;
  setRefreshData: React.Dispatch<
    React.SetStateAction<api.types.IRefreshData | null>
  >;
  setTask: React.Dispatch<
    React.SetStateAction<api.types.DiplomasTask | undefined>
  >;
};

function DiplomasPageConfirmation({
  templateId,
  setDelete,
  isDelete,
  deleteId,
  setDeleteId,
  deleteAndCreateData,
  setDeleteAndCreateData,
  setGroupTask,
  refreshData,
  setRefreshData,
  setTask,
}: ComponentProps) {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(accessTokenSelector)!;
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const deleteDiplomas = async () => {
    if (!deleteAndCreateData) return;

    const { idsToDelete } = deleteAndCreateData;
    for (const id of idsToDelete) {
      await dispatch(
        deleteDiplomaThunk({
          id,
          access_token: accessToken,
        })
      );
    }
  };

  return (
    <>
      <ConfirmationDialog
        onClose={() => setDelete(false)}
        title="Удалить дипломы"
        text="Вы действительно хотите удалить дипломы, связанные с текущим шаблоном?"
        open={isDelete}
        onSubmit={() =>
          dispatch(
            deleteTemplateDiplomasThunk({
              templateId: templateId,
              access_token: accessToken,
            })
          ).then(() => {
            setDelete(false);
            history.push({
              ...history.location,
              search: '',
            });
          })
        }
      />
      <ConfirmationDialog
        open={!!deleteId}
        title="Удалить диплом"
        text="Вы действительно хотите удалить диплом?"
        onClose={() => setDeleteId(undefined)}
        onSubmit={() =>
          dispatch(
            deleteDiplomaThunk({
              id: deleteId!,
              access_token: accessToken,
            })
          ).then(() => {
            setDeleteId(undefined);
          })
        }
      />
      <ConfirmationDialog
        open={!!deleteAndCreateData}
        title="Удалить дипломы"
        text="С выбранными достижениями уже связаны дипломы. Мы рекомендуем удалить дипломы перед созданием новых. Удалить дипломы?"
        submitText="Удалить"
        cancelText="Оставить"
        disabled={isButtonsDisabled}
        cancelDisabled={isButtonsDisabled}
        onClose={() => {
          if (!deleteAndCreateData || isButtonsDisabled) return;
          api
            .createDiplomasTask(accessToken, {
              template_id: templateId,
              only_achievements: deleteAndCreateData.achForCreate,
            })
            .then((res) => {
              setGroupTask(res.data);
              setDeleteAndCreateData(null);
            });
        }}
        onSubmit={() => {
          if (!deleteAndCreateData) return;
          setIsButtonsDisabled(true);
          deleteDiplomas()
            .then(() =>
              api.createDiplomasTask(accessToken, {
                template_id: templateId,
                only_achievements: deleteAndCreateData.achForCreate,
              })
            )
            .then((res) => {
              setGroupTask(res.data);
              setDeleteAndCreateData(null);
              setIsButtonsDisabled(false);
            });
        }}
      />
      <ConfirmationDialog
        open={!!refreshData}
        title="Удалить диплом"
        text="При новом создании диплома мы рекомендуем удалить предыдущий. Удалить диплом?"
        submitText="Удалить"
        cancelText="Оставить"
        onClose={() => {
          if (!refreshData) {
            return;
          }
          api
            .createDiplomasTask(accessToken, {
              template_id: refreshData.template_id,
              only_achievements: refreshData.only_achievements,
            })
            .then((res) => {
              setTask(res.data);
              setRefreshData(null);
            });
        }}
        onSubmit={() => {
          if (!refreshData) {
            return;
          }
          dispatch(
            deleteDiplomaThunk({
              id: refreshData.diplomaId,
              access_token: accessToken,
            })
          )
            .then(() => {
              return api.createDiplomasTask(accessToken, {
                template_id: refreshData.template_id,
                only_achievements: refreshData.only_achievements,
              });
            })
            .then((res) => {
              setTask(res.data);
              setRefreshData(null);
            });
        }}
      />
    </>
  );
}

export default DiplomasPageConfirmation;
