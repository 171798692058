import * as api from 'app/api';

export enum FontFamily {
  Roboto = 'Roboto',
  Montserrat = 'Montserrat',
  OpenSans = 'Open Sans',
  PTSerif = 'PT Serif',
  RobotoCondensed = 'Roboto Condensed',
  Ubuntu = 'Ubuntu',
  Arial = 'Arial',
  Helvetica = 'Helvetica',
  Verdana = 'Verdana',
  Georgia = 'Georgia',
  CourierNew = 'Courier New',
  Commissioner = 'Commissioner',
}

export function getFontFamilyOptions() {
  return Object.values(FontFamily).map((family) => ({
    title: family,
    value: family,
  }));
}

export function getBlockTitle(type: api.local.TemplateBlockType) {
  switch (type) {
    case api.local.TemplateBlockType.BACKGROUND:
      return 'Фон';
    case api.local.TemplateBlockType.TEXT:
      return 'Текст';
    case api.local.TemplateBlockType.CONDITIONAL_TEXT:
      return 'Текст по условию';
    default:
      return 'Unknown';
  }
}

export function getBlockTypeOptions() {
  return Object.values(api.local.TemplateBlockType).map((type) => ({
    title: getBlockTitle(type),
    value: type,
  }));
}

export function getDefaultBlockInput(
  type: api.local.TemplateBlockType
): api.local.TemplateBlockInput {
  switch (type) {
    case api.local.TemplateBlockType.BACKGROUND:
      return { type, payload: { size: 100, opacity: 1 } };
    case api.local.TemplateBlockType.CONDITIONAL_TEXT:
      return {
        type,
        payload: {
          content: 'Текст',
          x: 0,
          y: 0,
          width: 250,
          height: 40,
          alt_content: 'Текст',
          conditional_field: 'user.sex',
        },
      };
    case api.local.TemplateBlockType.TEXT:
      return {
        type,
        payload: { content: 'Текст', x: 0, y: 0, width: 250, height: 40 },
      };
  }
}
