import * as api from 'app/api';

export type PaperSize = {
  width: number;
  height: number;
};

export const sizeByFormat: Record<api.types.TemplateFormat, PaperSize> = {
  [api.types.TemplateFormat.A4]: { width: 210, height: 297 },
  [api.types.TemplateFormat.A5]: { width: 148, height: 210 },
};

export const titleByOrientation: Record<
  api.types.TemplateOrientation,
  string
> = {
  [api.types.TemplateOrientation.PORTRAIT]: 'Книжная',
  [api.types.TemplateOrientation.LANDSCAPE]: 'Альбомная',
};

export function getPaperSize(
  format: api.types.TemplateFormat,
  orientation: api.types.TemplateOrientation
): PaperSize {
  const size = sizeByFormat[format];

  if (orientation === api.types.TemplateOrientation.LANDSCAPE) {
    return { width: size.height, height: size.width };
  }

  return size;
}

export function getFormatOptions() {
  return Object.values(api.types.TemplateFormat).map((format) => ({
    title: format as string,
    value: format,
  }));
}

export function getOrientationTitle(
  orientation: api.types.TemplateOrientation
) {
  switch (orientation) {
    case api.types.TemplateOrientation.PORTRAIT:
      return 'Книжная';
    case api.types.TemplateOrientation.LANDSCAPE:
      return 'Альбомная';
  }
}

export function getDiplomaType() {
  return [
    {
      title: 'Персональный',
      value: false,
    },
    {
      title: 'Командный',
      value: true,
    },
  ];
}

export function getOrientationOptions() {
  return Object.values(api.types.TemplateOrientation).map((orientation) => ({
    title: titleByOrientation[orientation],
    value: orientation,
  }));
}

export const defaultTemplateInput: api.types.TemplateInput = {
  title: '',
  format: api.types.TemplateFormat.A4,
  orientation: api.types.TemplateOrientation.PORTRAIT,
  role: '',
  for_teams: false,
};
