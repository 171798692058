import {
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ComponentProps } from 'react';
import * as api from 'app/api';
import { ChevronRight } from '@material-ui/icons';
import moment from 'moment';

type Props = {
  event: api.types.Event;
  isUserAdmin?: boolean;
} & ComponentProps<typeof ListItem> &
  Record<any, any>;

export default function EventListItem({
  event,
  isUserAdmin = false,
  ...restProps
}: Props) {
  return (
    <ListItem {...restProps}>
      <ListItemText
        primary={event.title}
        secondary={
          <>
            <span style={{ display: 'block' }}>
              {event.start && moment(event.start).format('DD.MM.YYYY')}
            </span>
            {!isUserAdmin && (
              <Typography
                component="span"
                variant="body2"
                color="error"
                style={{ marginTop: '4px', display: 'block' }}
              >
                Вы не являетесь участником организации{' '}
                {!!event.organization?.id && (
                  <span>ID {event.organization.id}</span>
                )}
              </Typography>
            )}
          </>
        }
      />
      {!!isUserAdmin && (
        <ListItemSecondaryAction>
          <ChevronRight />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
