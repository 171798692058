import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import { ComponentProps, useMemo } from 'react';
import { Routes } from 'app/router/Routes';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'app/store/user';

import HeaderUser from './HeaderUser';

type Props = ComponentProps<typeof AppBar> & {
  title?: string;
};

export default function Header({ title, children, ...restProps }: Props) {
  const history = useHistory();
  const user = useSelector(userSelector);
  const tabs = useMemo(
    () => [
      {
        href: Routes.INDEX,
        exact: true,
        label: 'Мероприятия',
      },
    ],
    []
  );
  const currentTab = useMemo(
    () =>
      tabs.find((tab) =>
        tab.exact
          ? history.location.pathname === tab.href
          : history.location.pathname.startsWith(tab.href)
      ),
    [history.location.pathname, tabs]
  );
  return (
    <AppBar position="sticky" {...restProps}>
      <Toolbar component={Container} variant="dense">
        <Tabs component="nav" value={currentTab?.href}>
          {tabs.map((tab) => (
            <StyledTab
              key={tab.href}
              value={tab.href}
              component={NavLink}
              to={tab.href}
              label={tab.label}
            />
          ))}
        </Tabs>
        <Box flex={1} />
        {user && (
          <HeaderUser
            onLogout={() => history.push(Routes.LOGOUT)}
            user={user}
          />
        )}
      </Toolbar>
      {children}
    </AppBar>
  );
}

const StyledTab = styled(Tab)`
  min-width: auto;
` as typeof Tab;
