import Main from 'app/components/Main';
import Header from 'app/components/Header';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { accessTokenSelector } from 'app/store/auth';
import { useEffect, useMemo, useState } from 'react';
import { AppDispatch, RootState } from 'app/store';
import eventSlice, {
  createEventTemplateThunk,
  deleteEventTemplateThunk,
  fetchEventTemplatesThunk,
  fetchEventThunk,
} from 'app/store/event';
import {
  Box,
  Button,
  Fab,
  List,
  ListSubheader,
  Typography,
  Link as MLink,
} from '@material-ui/core';
import { Add, ArrowBack } from '@material-ui/icons';
import { Routes } from 'app/router/Routes';
import { useUnmount } from 'react-use';
import TemplateListItem from 'app/components/TemplateListItem';
import TemplateDialog from 'app/components/TemplateDialog';
import { defaultTemplateInput } from 'app/services/templates';
import { compile } from 'path-to-regexp';
import * as api from 'app/api';
import ConfirmationDialog from 'app/components/ConfirmationDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import EventPickerDialog from 'app/components/EventPickerDialog';
import qs from 'query-string';
import { Info } from '@material-ui/icons';

type Props = RouteComponentProps<{ id: string }>;

export default function EventPage({ match, history }: Props) {
  const id = Number(match.params.id);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(accessTokenSelector)!;
  const eventState = useSelector((state: RootState) => state.event);
  const instructionUrl =
    'https://glib-holiday-179.notion.site/ba00a0a484f74775bd822ab86e6bd5b6';
  const [deleteTemplateId, setDeleteTemplateId] = useState<
    api.types.Template['id']
  >();
  const [cloneTemplateId, setCloneTemplateId] = useState<
    api.types.Template['id']
  >();
  const [clonedTemplate, setClonedTemplate] = useState<api.types.Template>();
  const [isCreateTemplate, setCreateTemplate] = useState(false);
  const queryParams = useMemo(() => qs.parse(history.location.search), [
    history.location.search,
  ]);
  const isSimpleRegistration = !!eventState?.data?.simplified_registration;

  useEffect(() => {
    dispatch(fetchEventThunk({ access_token: accessToken, id }));
  }, [accessToken, dispatch, id]);

  useEffect(() => {
    if (eventState.data) {
      dispatch(
        fetchEventTemplatesThunk({
          id: eventState.data.id,
          access_token: accessToken,
        })
      );
    }
  }, [accessToken, dispatch, eventState.data]);

  useUnmount(() => {
    dispatch(eventSlice.actions.reset());
  });
  return (
    <>
      <Header />
      <Main>
        <Box flex={1} display="flex">
          <Button
            component={Link}
            to={{
              pathname: Routes.INDEX,
              search: `?${qs.stringify(queryParams)}`,
            }}
            variant="outlined"
            startIcon={<ArrowBack />}
          >
            Мероприятия
          </Button>

          <p style={{ marginLeft: '16px' }}>
            <MLink
              href={instructionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Инструкция
            </MLink>{' '}
            по&nbsp;созданию дипломов
          </p>
        </Box>

        {eventState.data && (
          <>
            <Box paddingY={2}>
              <Typography variant="h5">{eventState.data.title}</Typography>
              {isSimpleRegistration && (
                <Box
                  border={'1px solid'}
                  padding={1}
                  marginTop={2}
                  borderRadius="4px"
                  borderColor="#f44336"
                  display="flex"
                  alignItems={'center'}
                  style={{ gap: '8px' }}
                >
                  <Info style={{ color: '#f44336' }} />
                  <p style={{ margin: 0 }}>
                    Обратите внимание, генерация дипломов возможна только для
                    участников мероприятия с&nbsp;полными учетными данными.
                  </p>
                </Box>
              )}
              <Box mt={3}>
                <List
                  subheader={
                    <ListSubheader>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        Шаблоны ({eventState.templates?.length || 0})
                        <Fab
                          onClick={() => setCreateTemplate(true)}
                          color="secondary"
                          variant="extended"
                          size="small"
                        >
                          <Box mr={1} clone>
                            <Add />
                          </Box>
                          Новый шаблон
                        </Fab>
                      </Box>
                    </ListSubheader>
                  }
                >
                  {eventState.templates?.map((template) => (
                    <TemplateListItem
                      component={Link}
                      to={compile(Routes.TEMPLATE)({ id: template.id })}
                      button
                      key={template.id}
                      template={template}
                      onClone={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setCloneTemplateId(template.id);
                      }}
                      onDelete={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setDeleteTemplateId(template.id);
                      }}
                    />
                  ))}
                </List>
              </Box>
            </Box>
            <TemplateDialog
              open={isCreateTemplate}
              onClose={() => setCreateTemplate(false)}
              initialValues={defaultTemplateInput}
              achievementRoles={eventState.data?.achievement_roles}
              title="Создать шаблон"
              onSubmit={(input) =>
                dispatch(
                  createEventTemplateThunk({
                    eventId: id,
                    access_token: accessToken,
                    input,
                  })
                )
                  .then(unwrapResult)
                  .then((template) => {
                    history.push(compile(Routes.TEMPLATE)({ id: template.id }));
                  })
              }
            />
            <ConfirmationDialog
              onClose={() => setDeleteTemplateId(undefined)}
              title="Удалить шаблон"
              text="Вы действительно хотите удалить шаблон?"
              open={!!deleteTemplateId}
              onSubmit={() =>
                dispatch(
                  deleteEventTemplateThunk({
                    templateId: deleteTemplateId!,
                    access_token: accessToken,
                  })
                ).then(() => {
                  setDeleteTemplateId(undefined);
                })
              }
            />
            <EventPickerDialog
              open={!!cloneTemplateId}
              onSubmit={(eventId) => {
                api
                  .cloneTemplate(accessToken, cloneTemplateId!, {
                    event_id: eventId,
                  })
                  .then((res) => {
                    if (res.data.event_id === id) {
                      dispatch(
                        fetchEventTemplatesThunk({
                          id: id,
                          access_token: accessToken,
                        })
                      );
                    }
                    setClonedTemplate(res.data);
                    setCloneTemplateId(undefined);
                  })
                  .catch(console.error);
              }}
              onClose={() => setCloneTemplateId(undefined)}
            />
            <ConfirmationDialog
              open={!!clonedTemplate}
              onClose={() => setClonedTemplate(undefined)}
              title="Шаблон успешно склонирован"
              text="Перейти к шаблону?"
              onSubmit={() => {
                history.push(
                  compile(Routes.TEMPLATE)({ id: clonedTemplate!.id })
                );
                setClonedTemplate(undefined);
              }}
            />
          </>
        )}
      </Main>
    </>
  );
}
