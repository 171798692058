import * as api from 'app/api';
import { RenderState } from 'app/store/render';

import BackgroundBlock from './BackgroundBlock';
import TextBlock from './TextBlock';
import ConditionTextBlock from './ConditionTextBlock';

export type BaseBlockProps<T> = {
  block: T;
  onChange?: (block: T) => any;
  isSelected?: boolean;
  onSelect?: (id?: string) => any;
  onError?: (message: string) => any;
  renderState?: RenderState;
  isRender?: boolean;
};

export default function TemplateBlock(
  props: BaseBlockProps<api.local.TemplateBlock>
) {
  switch (props.block.type) {
    case api.local.TemplateBlockType.BACKGROUND:
      return <BackgroundBlock {...(props as any)} />;
    case api.local.TemplateBlockType.TEXT:
      return <TextBlock {...(props as any)} />;
    case api.local.TemplateBlockType.CONDITIONAL_TEXT:
      return <ConditionTextBlock {...(props as any)} />;
    default:
      return null;
  }
}
