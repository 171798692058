import { EventEmitter } from 'events';

import { useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { store } from 'app/App';
import renderSlice, { RenderState } from 'app/store/render';
import { useEffect } from 'react';
import TemplatePaper from 'app/editor/TemplatePaper';

const READY_EVENT = 'ready';

class RenderControls {
  events = new EventEmitter();
  ready = this.createReadyPromise();
  errorLogs: string[] = [];

  setState(state: RenderState | null) {
    store.dispatch(renderSlice.actions.set(state));
  }

  createReadyPromise() {
    return new Promise<void>((resolve) => {
      this.events.on(READY_EVENT, () => resolve());
    });
  }

  reset() {
    this.setState(null);
    this.ready = this.createReadyPromise();
    this.errorLogs = [];
  }
}

const renderControls = new RenderControls();
(window as any).RENDER_CONTROLS = renderControls;

export default function RenderPage() {
  const state = useSelector((state: RootState) => state.render);
  useEffect(() => {
    if (state) {
      (document as any).fonts.ready.then(() =>
        renderControls.events.emit(READY_EVENT)
      );
    }
  }, [state]);
  return state ? (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
            @page {
                size: ${
                  state.template.format
                } ${state.template.orientation.toLowerCase()};
                margin: 0;
            }

            html,
            body {
                height: 100%;
                margin: 0 !important;
                padding: 0 !important;
                overflow: hidden;
                -webkit-print-color-adjust: exact;
            }

            @media print {
              #paper {
                position: fixed;
                width: 100%;
                height: 100%;
              }
            }
        `,
        }}
      />
      <TemplatePaper
        elevation={0}
        id="paper"
        renderState={state}
        template={state.template}
        blocks={state.blocks}
        onError={(message) => renderControls.errorLogs.push(message)}
        isRender={true}
      />
    </>
  ) : null;
}
