import Header from 'app/components/Header';
import Main from 'app/components/Main';

export default function NotFoundPage() {
  return (
    <>
      <Header />
      <Main>Not found</Main>
    </>
  );
}
