import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'app/api';

export type EventState = {
  loading: boolean;
  data?: api.types.Event;
  templates?: api.types.Template[];
  eventFields?: api.types.EventFields[];
};

export const initialState: EventState = {
  loading: false,
};

export const fetchEventThunk = createAsyncThunk(
  'event/fetch',
  (
    args: { id: api.types.Event['id']; access_token: string },
    { rejectWithValue }
  ) =>
    api
      .getEvent(args.access_token, args.id)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const fetchEventTemplatesThunk = createAsyncThunk(
  'event/fetchTemplates',
  (
    args: { id: api.types.Event['id']; access_token: string },
    { rejectWithValue }
  ) =>
    api
      .getEventTemplates(args.access_token, args.id)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const fetchEventFieldsThunk = createAsyncThunk(
  'event/fetchFields',
  (
    args: { id: api.types.Event['id']; access_token: string },
    { rejectWithValue }
  ) =>
    api
      .getEventFields(args.access_token, args.id)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const createEventTemplateThunk = createAsyncThunk(
  'event/createTemplate',
  (
    args: {
      eventId: api.types.Event['id'];
      input: api.types.TemplateInput;
      access_token: string;
    },
    { rejectWithValue }
  ) =>
    api
      .createEventTemplate(args.access_token, args.eventId, args.input)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const deleteEventTemplateThunk = createAsyncThunk(
  'event/deleteTemplate',
  (
    args: {
      templateId: api.types.Template['id'];
      access_token: string;
    },
    { rejectWithValue }
  ) =>
    api
      .deleteTemplate(args.access_token, args.templateId)
      .then(() => args.templateId)
      .catch(rejectWithValue)
);

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchEventThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchEventThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEventTemplatesThunk.fulfilled, (state, action) => {
        state.templates = action.payload;
      })
      .addCase(fetchEventFieldsThunk.fulfilled, (state, action) => {
        state.eventFields = action.payload;
      })
      .addCase(createEventTemplateThunk.fulfilled, (state, action) => {
        state.templates = [...(state.templates || []), action.payload];
      })
      .addCase(deleteEventTemplateThunk.fulfilled, (state, action) => {
        state.templates = state.templates?.filter(
          (template) => template.id !== action.payload
        );
      }),
});

export default eventSlice;
