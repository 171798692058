import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from '@material-ui/core';
import { getBlockTitle } from 'app/services/blocks';
import * as api from 'app/api';
import styled from 'styled-components';
import { Delete, ExpandMore } from '@material-ui/icons';
import { ComponentProps } from 'react';

type Props = {
  isSelected?: boolean;
  onSelect?: () => any;
  onDelete?: ComponentProps<typeof IconButton>['onClick'];
  block: api.local.TemplateBlock;
} & ComponentProps<typeof Root>;

export default function BlockItem({
  block,
  isSelected,
  onSelect,
  onDelete,
  children,
  ...restProps
}: Props) {
  return (
    <Root
      expanded={isSelected}
      onChange={(event: any) => {
        event.preventDefault();
        event.stopPropagation();
        onSelect?.();
      }}
      {...restProps}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="body1">{getBlockTitle(block.type)}</Typography>
        <IconButton size="small" onClick={onDelete}>
          <Delete color="error" />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Root>
  );
}

const Root = styled(Accordion).attrs(() => ({
  classes: {
    expanded: 'expanded',
  },
  TransitionProps: {
    unmountOnExit: true,
  },
  elevation: 0,
}))`
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);

  &:before {
    display: none;
  }

  &.expanded {
    margin: 0;

    .MuiAccordionSummary-root {
      border-bottom: inherit;
    }
  }

  .MuiAccordionSummary {
    &-root {
      height: 56px;
      min-height: 56px;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
