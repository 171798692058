import { Button } from '@material-ui/core';
import { ComponentProps, useCallback, useRef } from 'react';

type Props = {
  value?: string;
  onChange?: (value?: string) => any;
} & Omit<ComponentProps<typeof Button>, 'value' | 'onChange'>;

export default function ImageUpload({ value, onChange, ...restProps }: Props) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files?.[0]) {
        const reader = new FileReader();
        reader.onload = () => onChange?.(String(reader.result));
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    [onChange]
  );
  const handleButton = useCallback(
    () => (value ? onChange?.(undefined) : inputRef.current?.click()),
    [onChange, value]
  );
  return (
    <Button
      color="secondary"
      variant="outlined"
      onClick={handleButton}
      {...restProps}
    >
      {value ? 'Удалить изображение' : 'Загрузить изображение'}
      <input
        ref={inputRef}
        type="file"
        hidden
        accept="image/*"
        value=""
        onChange={handleInput}
      />
    </Button>
  );
}
