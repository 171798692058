import styled, { css } from 'styled-components';
import { ComponentProps } from 'react';
import { diplomaTeamFields, fonts } from 'app/constants/editor';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import {
  EducationFields,
  OrganizationFields,
  toKey,
} from 'app/constants/editor';

import { palette } from './ColorPicker';
import {
  fontValues,
  fieldValues,
  letterSpacingValues,
  lineHeightValues,
  sizeValues,
} from './RichText';

type Props = ComponentProps<typeof Root>;

export const TOOLBAR_ID = 'rich-text-toolbar';

export default function RichTextToolbar(props: Props) {
  const eventState = useSelector((state: RootState) => state.event.eventFields);
  const { data: template } = useSelector((state: RootState) => state.template);
  const prefix = 'event_request.fields.';
  const eventFieldsNames = eventState?.reduce<any[]>((res, item) => {
    if (item.type === 'organization' || item.type === 'education') {
      const fieldsFromType =
        item.type === 'organization' ? OrganizationFields : EducationFields;

      const fields: string[] = [
        ...fieldsFromType.map(toKey(`${prefix}${item.name}`)),
      ];

      res = [...res, ...fields];
    } else if (item.type === 'checkbox') {
      res.push(`${prefix}checkbox.${item.name}`);
    } else {
      res.push(`${prefix}${item.name}`);
    }
    return res;
  }, []);

  let extendedFieldValues = [];
  if (template?.for_teams) {
    extendedFieldValues = [false, ...diplomaTeamFields];
  } else {
    extendedFieldValues = Array.isArray(eventFieldsNames)
      ? [...fieldValues, ...eventFieldsNames]
      : [...fieldValues];
  }

  return (
    <Root id={TOOLBAR_ID} {...props}>
      <span className="ql-formats">
        <select className="ql-font">
          {fontValues.map((value: any, i: number) => (
            <option key={i} selected={!value} value={value || undefined} />
          ))}
        </select>
        <select className="ql-size">
          {sizeValues.map((value: any, i: number) => (
            <option key={i} selected={!value} value={value || undefined} />
          ))}
          <option value="custom" />
        </select>
        <select className="ql-field">
          {extendedFieldValues.map((value: any, i: number) => (
            <option key={i} selected={!value} value={value || undefined} />
          ))}
        </select>
        <select className="ql-line-height">
          {lineHeightValues.map((value: any, i) => (
            <option key={i} selected={!value} value={value || undefined} />
          ))}
          <option value="custom" />
        </select>
        <select className="ql-letter-spacing">
          {letterSpacingValues.map((value: any, i) => (
            <option key={i} selected={!value} value={value || undefined} />
          ))}
          <option value="custom" />
        </select>
      </span>
      <span className="ql-formats">
        <button className="ql-code" />
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline" />
        <button className="ql-strike" />
        <select className="ql-text-transform">
          <option selected></option>
          <option value="uppercase"></option>
          <option value="lowercase"></option>
          <option value="capitalize"></option>
        </select>
        <select className="ql-color">
          <option selected></option>
          {Object.values(palette).map((color) => (
            <option key={color} value={color} />
          ))}
          <option value="custom" />
        </select>
        <select className="ql-background">
          <option selected></option>
          {Object.values(palette).map((color) => (
            <option key={color} value={color} />
          ))}
          <option value="custom" />
        </select>
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
        <select className="ql-align">
          <option selected></option>
          <option value="center"></option>
          <option value="right"></option>
          <option value="justify"></option>
        </select>
      </span>
    </Root>
  );
}

const Root = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  /* Опции для шрифта */
  ${Object.entries(fonts).map(
    ([key, value]) => css`
      .ql-font .ql-picker-item,
      .ql-font .ql-picker-label {
        &[data-value=${key}]:before {
          content: '${value.name}';
          font-family: ${value.name};
        }
      }
    `
  )}

  .ql-size .ql-picker-item,
  .ql-size .ql-picker-label {
    /* Опции для размера текста. */
    &[data-value]::before {
      content: attr(data-value);
    }
  }

  .ql-field .ql-picker-item,
  .ql-field .ql-picker-label {
    /* Опции для выбора поля. */
    &::before {
      content: 'Поле';
    }
    &[data-value]::before {
      content: attr(data-value);
    }
  }

  .ql-line-height .ql-picker-item,
  .ql-line-height .ql-picker-label {
    &::before {
      content: 'Межстрочный интервал';
    }
    /* Опции для line-height. */
    &[data-value]::before {
      content: attr(data-value);
    }
  }

  .ql-letter-spacing .ql-picker-item,
  .ql-letter-spacing .ql-picker-label {
    &::before {
      content: 'Межбуквенное расстояние';
    }
    /* Опции для letter-spacing. */
    &[data-value]::before {
      content: attr(data-value);
    }
  }

  .ql-text-transform .ql-picker-item,
  .ql-text-transform .ql-picker-label {
    &.ql-picker-label::before {
      content: 'Aa';
    }

    &.ql-picker-item::before {
      content: '-';
    }

    /* Опции для text-transform. */
    &[data-value]::before {
      content: 'Aa';
    }

    &[data-value='lowercase']::before {
      text-transform: lowercase;
    }

    &[data-value='uppercase']::before {
      text-transform: uppercase;
    }

    &[data-value='capitalize']::before {
      text-transform: capitalize;
    }
  }

  .ql-picker .ql-picker-options {
    max-height: 50vh;
    overflow-y: auto;
  }

  .ql-font,
  .ql-size,
  .ql-field,
  .ql-line-height,
  .ql-letter-spacing,
  .ql-text-transform {
    .ql-picker-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 24px;
    }
  }

  .ql-size,
  .ql-line-height,
  .ql-letter-spacing {
    .ql-picker-item,
    .ql-picker-label {
      &[data-value='custom']:before {
        content: 'Указать';
      }
    }
  }

  .ql-color .ql-picker-item,
  .ql-background .ql-picker-item {
    &[data-value='custom'] {
      background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100%
      );
    }
  }
`;
