import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from 'app/api';

export type RenderState = {
  template: api.types.Template;
  blocks: api.local.TemplateBlock[];
  achievement: any;
  education: any;
  user: any;
  event_request: any;
} | null;

export const initialState: RenderState = null;

const renderSlice = createSlice({
  name: 'render',
  initialState,
  reducers: {
    set: (_, action: PayloadAction<any>) => action.payload,
  },
});

export default renderSlice;
