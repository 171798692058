import {
  Avatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import * as api from 'app/api';
import { useState } from 'react';
import { ExitToApp } from '@material-ui/icons';
import styled from 'styled-components';

type Props = {
  user: api.types.User;
  onLogout?: () => any;
};

export default function HeaderUser({ user, onLogout }: Props) {
  const [anchorEl, setAnchorEl] = useState<Element>();
  return (
    <>
      <StyledAvatar
        src={user.avatar}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {!user.avatar && user.first_name.charAt(0)}
      </StyledAvatar>
      <Menu
        onClose={() => setAnchorEl(undefined)}
        open={!!anchorEl}
        anchorEl={anchorEl}
      >
        <MenuItem onClick={onLogout} dense>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Выйти" />
        </MenuItem>
      </Menu>
    </>
  );
}

const StyledAvatar = styled(Avatar)`
  cursor: pointer;
  color: inherit;
`;
