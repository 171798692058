import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import * as api from 'app/api';
import { ChangeEvent, ComponentProps, useEffect, useState } from 'react';
import {
  getDiplomaType,
  getFormatOptions,
  getOrientationOptions,
} from 'app/services/templates';
import { usePrevious } from 'react-use';
import { useLocation } from 'react-router-dom';

type Props = Omit<ComponentProps<typeof Dialog>, 'onSubmit' | 'title'> & {
  onSubmit?: (data: api.types.TemplateInput) => any;
  disabled?: boolean;
  title?: React.ReactNode;
  submitText?: React.ReactNode;
  cancelText?: React.ReactNode;
  enableReinitialize?: boolean;
  initialValues: api.types.TemplateInput;
  achievementRoles?: api.types.Event['achievement_roles'];
};

const ALL_ROLES = { id: '', name: 'Все достижения' };

export default function TemplateDialog({
  onSubmit,
  disabled,
  title,
  initialValues,
  cancelText = 'Отменить',
  submitText = 'Подтвердить',
  enableReinitialize,
  achievementRoles,
  ...restProps
}: Props) {
  const [values, setValues] = useState(initialValues);
  const [chosenRoles, setChosenRoles] = useState<string[]>(
    values.role.split(',')
  );
  const { pathname } = useLocation();
  const isCreateTemplatePage = pathname && pathname.includes('/events/');
  const prevInitialValues = usePrevious(initialValues);
  const getRolesForSelect = () => {
    if (achievementRoles && achievementRoles.length) {
      return [ALL_ROLES, ...achievementRoles];
    }
    return [ALL_ROLES];
  };
  useEffect(() => {
    if (
      enableReinitialize &&
      JSON.stringify(initialValues) !== JSON.stringify(prevInitialValues)
    ) {
      setValues(initialValues);
    }
  }, [enableReinitialize, initialValues, prevInitialValues]);
  const handleChange = (
    e: ChangeEvent<{ name?: string | undefined; value: string[] | unknown }>,
    child: any
  ) => {
    const value = e.target.value;
    if (!Array.isArray(value)) return;
    const incomingValue = child?.props.value;
    if (incomingValue === '' && value?.includes(incomingValue)) {
      setChosenRoles(['']);
    } else if (incomingValue && value.includes('')) {
      const idx = value.findIndex((v: string) => v === '');
      if (idx >= 0) {
        value.splice(idx, 1);
      }
      setChosenRoles(value);
    } else {
      setChosenRoles(value);
    }
  };
  return (
    <Dialog disableBackdropClick maxWidth="sm" fullWidth {...restProps}>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const formData = { ...values };
          if (chosenRoles.includes('')) {
            formData.role = '';
          } else {
            formData.role = chosenRoles.join(',');
          }
          const returned = onSubmit?.(formData);
          if (returned instanceof Promise) {
            returned.then(() => setValues(initialValues));
          }
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="normal"
            autoFocus
            fullWidth
            label="Название"
            value={values.title}
            onChange={({ target: { value } }) =>
              setValues((prev) => ({ ...prev, title: value }))
            }
          />
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel id="format-label">Формат</InputLabel>
            <Select
              labelId="format-label"
              label="Формат"
              value={values.format}
              onChange={({ target: { value } }) =>
                setValues((prev) => ({
                  ...prev,
                  format: value as api.types.TemplateFormat,
                }))
              }
            >
              {getFormatOptions().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel id="orientation-label">Ориентация</InputLabel>
            <Select
              labelId="orientation-label"
              label="Ориентация"
              value={values.orientation}
              onChange={({ target: { value } }) =>
                setValues((prev) => ({
                  ...prev,
                  orientation: value as api.types.TemplateOrientation,
                }))
              }
            >
              {getOrientationOptions().map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isCreateTemplatePage && (
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel id="for-teams">Тип Диплома</InputLabel>
              <Select
                labelId="for-teams"
                label="Тип Диплома"
                value={values.for_teams}
                onChange={({ target: { value } }) =>
                  setValues((prev) => ({
                    ...prev,
                    for_teams: value as api.types.ForTeamsDiploma,
                  }))
                }
              >
                {getDiplomaType().map((option, idx) => (
                  <MenuItem
                    key={'diplomaType' + idx}
                    value={option.value as any}
                  >
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel id="achievement-label">Роль</InputLabel>
            <Select
              labelId="achievement-label"
              label="Роль"
              multiple
              value={chosenRoles}
              onChange={handleChange}
            >
              {getRolesForSelect().map((option) => (
                <MenuItem key={option.id} value={String(option.id)}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={restProps.onClose as any}>{cancelText}</Button>
          <Button disabled={disabled} type="submit" color="primary">
            {submitText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
