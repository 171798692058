import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as api from 'app/api';
import { useSelector } from 'react-redux';
import { accessTokenSelector } from 'app/store/auth';

type Props = ComponentProps<typeof Dialog> & {
  task: api.types.DiplomasTask;
  pollInterval?: number;
  onPoll?: () => any;
  onFinish?: () => any;
};

const PROGRESS_INTERVAL = 2000;

export default function DiplomasTaskDialog({
  task,
  pollInterval = 2000,
  onPoll,
  onClose,
  onFinish,
  ...restProps
}: Props) {
  const accessToken = useSelector(accessTokenSelector)!;
  const status = useMemo(() => {
    switch (task.status) {
      case api.types.TaskStatus.CREATED:
        return 'задача создана';
      case api.types.TaskStatus.PENDING:
        return `в процессе`;
      case api.types.TaskStatus.COMPLETED:
        return !task.meta?.total
          ? 'не удалось найти достижения'
          : 'создаем дипломы';
      case api.types.TaskStatus.ERROR:
        return 'ошибка, перезапуск задачи...';
      case api.types.TaskStatus.FATAL:
        return 'критическая ошибка';
    }
  }, [task]);
  const [progress, setProgress] = useState({
    Current: 0,
    Total: 0,
  });
  const [isDiplomasFetched, setIsDiplomaFetched] = useState(false);
  const fatal = task.status === api.types.TaskStatus.FATAL;
  const finished =
    task.status === api.types.TaskStatus.FATAL ||
    task.status === api.types.TaskStatus.COMPLETED;

  const shouldPollingProgress =
    task.status === api.types.TaskStatus.COMPLETED &&
    task.meta?.total &&
    !isDiplomasFetched;

  const progreesVariant = fatal
    ? 'determinate'
    : progress.Total
    ? 'determinate'
    : 'indeterminate';

  const progressValue = fatal
    ? 100
    : progress.Total
    ? Math.round((progress.Current / progress.Total) * 100)
    : undefined;
  const handleClose = finished ? onClose : undefined;
  const pollPropgress = useCallback(() => {
    try {
      api.getDiplomasProgress(accessToken, task.id).then((res) => {
        if (res.data?.Total === res.data?.Current) {
          setIsDiplomaFetched(true);
        }

        setProgress({ ...progress, ...res.data });
      });
    } catch (err) {
      setIsDiplomaFetched(true);
    }
  }, [accessToken, progress, task.id]);

  useEffect(() => {
    if (finished) {
      onFinish?.();
      return;
    }
    const timeout = setTimeout(onPoll, pollInterval);
    return () => clearTimeout(timeout);
  }, [finished, onFinish, onPoll, pollInterval, task.status]);

  useEffect(() => {
    if (shouldPollingProgress) {
      const progressTimeout = setTimeout(pollPropgress, PROGRESS_INTERVAL);
      return () => clearTimeout(progressTimeout);
    }
  }, [pollPropgress, shouldPollingProgress, task.status, isDiplomasFetched]);
  return (
    <Dialog onClose={handleClose} maxWidth="xs" fullWidth {...restProps}>
      <DialogTitle>Создание дипломов</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <Typography>Task ID: {task.id}</Typography>
          {!progress.Total ? (
            <Typography>Статус задачи: {status} </Typography>
          ) : null}
          {progress.Total ? (
            <Typography>
              Статус создания дипломов:{' '}
              {progress.Total ? `(${progress.Current}/${progress.Total})` : ''}
            </Typography>
          ) : null}
        </Box>
        <LinearProgress
          color="secondary"
          variant={progreesVariant}
          value={progressValue}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!handleClose}
          onClick={handleClose as any}
          color="primary"
        >
          Закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
}
