import * as api from 'app/api';

import { BaseBlockProps } from './TemplateBlock';

type Props = BaseBlockProps<api.local.BackgroundBlock>;

export default function BackgroundBlock({ block }: Props) {
  return (
    <div
      style={{
        position: 'absolute',
        zIndex: block.payload?.zIndex,
        top: 0,
        left: 0,
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        backgroundSize: block.payload?.cover
          ? 'cover'
          : `${block.payload?.size}%`,
        backgroundColor: block.payload?.color,
        backgroundImage: block.payload?.url
          ? `url(${block.payload?.url})`
          : undefined,
        opacity: block.payload?.opacity,
        filter: `blur(${block.payload?.blur}px)`,
        backgroundPositionX: `${block.payload?.position_x}%`,
        backgroundPositionY: `${block.payload?.position_y}%`,
      }}
    />
  );
}
