import * as api from 'app/api';
import { Rnd } from 'react-rnd';
import { useRef, useState } from 'react';
import useClickOutside from 'use-click-outside';
import ReactQuill from 'react-quill';
import { RenderState } from 'app/store/render';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';

import { BaseBlockProps } from './TemplateBlock';
import RichText from './RichText';
import EditorViewport from './EditorViewport';

type Props = BaseBlockProps<api.local.TextBlock> & {
  renderState?: RenderState;
};

export default function TextBlock({
  block,
  isSelected,
  onSelect,
  onChange,
  renderState,
  onError,
  isRender,
}: Props) {
  const templateState = useSelector((state: RootState) => state.template);
  const [readOnly, setReadOnly] = useState(true);
  const editorRef = useRef<ReactQuill | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);
  useClickOutside(
    rootRef,
    (event: any) => {
      if (event.target.closest(EditorViewport.toString())) {
        if (!readOnly) {
          setReadOnly(true);
          return;
        }

        if (isSelected) {
          onSelect?.(undefined);
        }
      }
    },
    'mousedown'
  );
  return (
    <Rnd
      ref={(i) => (rootRef.current = i?.getSelfElement() as any)}
      size={{
        width: block.payload?.width as any,
        height: block.payload?.height as any,
      }}
      position={{
        x: block.payload?.x as any,
        y: block.payload?.y as any,
      }}
      disableDragging={!readOnly}
      onDragStop={(_, d) =>
        onChange?.({ ...block, payload: { ...block.payload, x: d.x, y: d.y } })
      }
      bounds="parent"
      style={{
        border: `1px dashed #000`,
        borderColor: isSelected ? undefined : 'transparent',
        zIndex: block.payload?.zIndex,
      }}
      onResize={(e, dir, ref, delta, position) =>
        onChange?.({
          ...block,
          payload: {
            ...block.payload,
            width: ref.offsetWidth,
            height: ref.offsetHeight,
            ...position,
          },
        })
      }
      enableUserSelectHack
      onClick={() => !isSelected && onSelect?.(block.id)}
      onDoubleClick={() => {
        if (readOnly) {
          setReadOnly(false);
          setTimeout(() => editorRef.current?.focus(), 0);
        }
      }}
    >
      {(templateState.isTemplatePageReady || isRender) && (
        <RichText
          id={block.id}
          renderState={renderState}
          editorRef={(instance) => (editorRef.current = instance)}
          readOnly={readOnly}
          value={block.payload?.content}
          onChange={(content) =>
            onChange?.({
              ...block,
              payload: { ...block.payload, content },
            })
          }
          showToolbar={isSelected}
          onError={onError}
          block={block}
        />
      )}
    </Rnd>
  );
}
