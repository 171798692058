import {
  FormControlLabel,
  Grid,
  Slider,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import * as api from 'app/api';
import { useCallback, useMemo } from 'react';

import ColorPicker from './ColorPicker';
import ImageUpload from './ImageUpload';

type Props = {
  block: api.local.TemplateBlock;
  onChange?: (block: Props['block']) => any;
};

export default function BlockForm({ block, onChange }: Props) {
  const handleChange = useCallback(
    (data: Partial<api.local.TemplateBlock['payload']>) =>
      onChange?.({
        ...block,
        payload: {
          ...block.payload,
          ...(data as any),
        },
      }),
    [block, onChange]
  );
  const zIndexField = useMemo(
    () => (
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Z-index"
          value={block.payload?.zIndex}
          type="number"
          onChange={(event) =>
            handleChange({ zIndex: Number(event.target.value) })
          }
        />
      </Grid>
    ),
    [block.payload?.zIndex, handleChange]
  );
  const content = useMemo(() => {
    switch (block.type) {
      case api.local.TemplateBlockType.BACKGROUND:
        return (
          <>
            <Grid item xs={12}>
              <Typography gutterBottom>Цвет</Typography>
              <ColorPicker
                fullWidth
                value={block.payload?.color}
                onChange={(color) =>
                  handleChange({
                    color,
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <ImageUpload
                fullWidth
                value={block.payload?.url}
                onChange={(url) => handleChange({ url })}
              />
            </Grid>
            {block.payload?.url && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={block.payload?.cover}
                      onChange={(_, value) => handleChange({ cover: value })}
                      color="primary"
                    />
                  }
                  label="Размер по высоте листа"
                />
              </Grid>
            )}
            {!block.payload?.cover && block.payload?.url && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Размер"
                  value={block.payload?.size}
                  type="number"
                  onChange={(event) =>
                    handleChange({ size: Number(event.target.value) })
                  }
                />
              </Grid>
            )}
            {block.payload?.url && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Смещение по ширине"
                  value={block.payload?.position_x}
                  type="number"
                  onChange={(event) =>
                    handleChange({ position_x: Number(event.target.value) })
                  }
                />
              </Grid>
            )}
            {!block.payload?.cover && block.payload?.url && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Смещение по высоте"
                  value={block.payload?.position_y}
                  type="number"
                  onChange={(event) =>
                    handleChange({ position_y: Number(event.target.value) })
                  }
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography gutterBottom>Насыщенность</Typography>
              <Slider
                min={0}
                step={0.1}
                onChange={(_, value) =>
                  handleChange({ opacity: value as number })
                }
                value={block.payload?.opacity}
                max={1}
                valueLabelDisplay="auto"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>Размытие</Typography>
              <Slider
                min={0}
                max={200}
                onChange={(_, value) => handleChange({ blur: value as number })}
                value={block.payload?.blur}
                valueLabelDisplay="auto"
              />
            </Grid>
          </>
        );
      case api.local.TemplateBlockType.TEXT:
        return (
          <>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Ширина"
                value={block.payload?.width}
                type="number"
                onChange={(event) =>
                  handleChange({ width: Number(event.target.value) })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Высота"
                value={block.payload?.height}
                type="number"
                onChange={(event) =>
                  handleChange({ height: Number(event.target.value) })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="X"
                value={block.payload?.x}
                type="number"
                onChange={(event) =>
                  handleChange({ x: Number(event.target.value) })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Y"
                value={block.payload?.y}
                type="number"
                onChange={(event) =>
                  handleChange({ y: Number(event.target.value) })
                }
              />
            </Grid>
          </>
        );
      case api.local.TemplateBlockType.CONDITIONAL_TEXT:
        return (
          <>
            <Grid item xs={12}>
              <p style={{ marginBottom: '5px' }}>
                Вы&nbsp;можете указать разный текст для юношей и&nbsp;девушек.
              </p>
              <p
                style={{
                  fontSize: '12px',
                  color: 'gray',
                  marginTop: 0,
                  lineHeight: '1.2',
                }}
              >
                Для каждого пола нужно указывать собственный текст.
                Вы&nbsp;можете это сделать переключаясь между вкладками
                &laquo;М&raquo; и&nbsp;&laquo;Ж&raquo;.
              </p>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Ширина"
                value={block.payload?.width}
                type="number"
                onChange={(event) =>
                  handleChange({ width: Number(event.target.value) })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Высота"
                value={block.payload?.height}
                type="number"
                onChange={(event) =>
                  handleChange({ height: Number(event.target.value) })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="X"
                value={block.payload?.x}
                type="number"
                onChange={(event) =>
                  handleChange({ x: Number(event.target.value) })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Y"
                value={block.payload?.y}
                type="number"
                onChange={(event) =>
                  handleChange({ y: Number(event.target.value) })
                }
              />
            </Grid>
          </>
        );
      default:
        return null;
    }
  }, [block, handleChange]);
  return (
    <Grid container spacing={2}>
      {content}
      {content && zIndexField}
    </Grid>
  );
}
