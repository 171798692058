import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from 'app/api';
import shortid from 'shortid';

import { deleteEventTemplateThunk } from './event';

import { RootState } from '.';

export type TemplateState = {
  loading: boolean;
  isTemplatePageReady: boolean;
  data?: api.types.Template;
  blocks?: api.local.TemplateBlock[];
  selectedBlock?: api.local.TemplateBlock['id'];
  task?: api.types.DiplomasTask;
  dirty: {
    deleted: Record<api.local.TemplateBlock['id'], boolean>;
    updated: Record<api.local.TemplateBlock['id'], boolean>;
  };
};

export const initialState: TemplateState = {
  loading: false,
  isTemplatePageReady: false,
  dirty: {
    deleted: {},
    updated: {},
  },
};

const tempIdPrefix = 'temp_';

function createTempId(): string {
  return tempIdPrefix + shortid();
}

function isTempId(id: string): boolean {
  return id.startsWith(tempIdPrefix);
}

export const fetchTemplateThunk = createAsyncThunk(
  'template/fetch',
  (
    args: { id: api.types.Template['id']; access_token: string },
    { rejectWithValue }
  ) =>
    api
      .getTemplate(args.access_token, args.id)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const fetchTemplateBlocksThunk = createAsyncThunk(
  'template/fetchBlocks',
  (
    args: {
      id: api.types.Template['id'];
      access_token: string;
    },
    { rejectWithValue }
  ) =>
    api
      .getTemplateBlocks(args.access_token, args.id)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const deleteTemplateThunk = deleteEventTemplateThunk;

export const createTemplateBlockThunk = createAsyncThunk(
  'template/createBlock',
  (input: api.local.TemplateBlockInput, { dispatch, getState }) => {
    const block: api.local.TemplateBlock = {
      ...input,
      id: createTempId(),
      template_id: (getState() as RootState).template.data!.id,
    };
    dispatch(templateSlice.actions.addBlock(block));
    return block;
  }
);

export const createDiplomasTaskThunk = createAsyncThunk(
  'template/createDiplomasTask',
  (
    args: {
      payload: api.types.DiplomasTaskPayload;
      access_token: string;
    },
    { rejectWithValue }
  ) =>
    api
      .createDiplomasTask(args.access_token, args.payload)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const fetchDiplomasTaskThunk = createAsyncThunk(
  'template/fetchDiplomasTask',
  (
    args: {
      taskId: api.types.DiplomasTask['id'];
      access_token: string;
    },
    { rejectWithValue }
  ) =>
    api
      .getDiplomasTask(args.access_token, args.taskId)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

export const saveThunk = createAsyncThunk(
  'template/save',
  async (access_token: string, { getState, dispatch }) => {
    const { data, blocks, dirty } = (getState() as RootState).template;
    const templateRes = await api.updateTemplate(access_token, data!.id, data!);
    dispatch(templateSlice.actions.setTemplate(templateRes.data));
    await Promise.all(
      Object.keys(dirty.deleted).map(
        (id): Promise<any> =>
          isTempId(id)
            ? Promise.resolve()
            : api.deleteTemplateBlock(access_token, id)
      )
    );
    await Promise.all(
      (blocks || []).map(
        (block): Promise<any> => {
          if (isTempId(block.id)) {
            return api.createTemplateBlock(
              access_token,
              block as any,
              data!.id
            );
          }
          if (dirty.updated[block.id]) {
            return api.updateTemplateBlock(access_token, block);
          }
          return Promise.resolve();
        }
      )
    );
    dispatch(templateSlice.actions.resetDirty());
    await dispatch(fetchTemplateBlocksThunk({ id: data!.id, access_token }));
  }
);

const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    reset: () => initialState,
    selectBlock: (
      state,
      action: PayloadAction<TemplateState['selectedBlock']>
    ) => {
      state.selectedBlock = action.payload;
    },
    updateBlock: (state, action: PayloadAction<api.local.TemplateBlock>) => {
      state.blocks = state.blocks?.map((block) =>
        block.id === action.payload.id ? action.payload : block
      );
      state.dirty.updated[action.payload.id] = true;
    },
    resetDirty: (state) => {
      state.dirty = initialState.dirty;
    },
    addBlock: (state, action: PayloadAction<api.local.TemplateBlock>) => ({
      ...state,
      blocks: [...(state.blocks || []), action.payload],
    }),
    updateTemplate: (
      state,
      action: PayloadAction<api.types.TemplateInput>
    ) => ({
      ...state,
      data: state.data && {
        ...state.data,
        ...action.payload,
      },
    }),
    resetTask: (state) => {
      state.task = initialState.task;
    },
    setTemplate: (state, action: PayloadAction<TemplateState['data']>) => {
      state.data = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setPageReady: (state, action: PayloadAction<boolean>) => {
      state.isTemplatePageReady = action.payload;
    },
    deleteBlock: (
      state,
      action: PayloadAction<api.local.TemplateBlock['id']>
    ) => {
      state.blocks = state.blocks?.filter(
        (block) => block.id !== action.payload
      );
      state.dirty.deleted[action.payload] = true;
      delete state.dirty.updated[action.payload];
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchTemplateThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchTemplateThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTemplateBlocksThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTemplateBlocksThunk.fulfilled, (state, action) => {
        state.blocks = action.payload;
        state.loading = false;
      })
      .addCase(createDiplomasTaskThunk.fulfilled, (state, action) => {
        state.task = action.payload;
      })
      .addCase(fetchDiplomasTaskThunk.fulfilled, (state, action) => {
        state.task = action.payload;
      })
      .addCase(saveThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(saveThunk.fulfilled, (state) => {
        state.loading = false;
      }),
});

export default templateSlice;
