import { ComponentProps } from 'react';
import { ColorPicker as MuiColorPicker } from 'material-ui-color';
import styled from 'styled-components';

type Props = Omit<
  ComponentProps<typeof MuiColorPicker>,
  'palette' | 'onChange'
> & {
  rootProps?: ComponentProps<typeof Root>;
  fullWidth?: boolean;
  onChange?: (value: string) => any;
};

export const palette = {
  White: '#FFFFFF',
  Silver: '#C0C0C0',
  Gray: '#808080',
  Black: '#000000',
  Red: '#FF0000',
  Yellow: '#FFFF00',
  Lime: '#00FF00',
  Green: '#008000',
  Aqua: '#00FFFF',
  Blue: '#0000FF',
  Purple: '#800080',
  Pink: '#FFAEBC',
  Orange: '#FFA500',
  Teal: '#009688',
  Brown: '#795548',
  Indigo: '#3F51B5',
  'Blue Gray': '#607D8B',
  Amber: '#FFC107',
};

export default function ColorPicker({
  rootProps,
  fullWidth,
  onChange,
  ...restProps
}: Props) {
  return (
    <Root $fullWidth={fullWidth} {...rootProps}>
      <MuiColorPicker
        onChange={(color) =>
          onChange?.(
            typeof color === 'object' ? `#${color.hex}` : (color as string)
          )
        }
        palette={palette}
        {...restProps}
      />
    </Root>
  );
}

const Root = styled.div<{ $fullWidth?: boolean }>`
  display: flex;

  & > div {
    width: 100%;

    & > div {
      flex: 1;
    }
  }
`;
