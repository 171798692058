import * as api from 'app/api';
import Paper from '@material-ui/core/Paper';
import { TemplateFormat, TemplateOrientation } from 'app/api/types';
import { getPaperSize } from 'app/services/templates';
import styled, { css } from 'styled-components';
import { ComponentProps } from 'react';
import { RenderState } from 'app/store/render';

import TemplateBlock from './TemplateBlock';

type Props = ComponentProps<typeof Root> & {
  template: api.types.Template;
  blocks?: api.local.TemplateBlock[];
  onChangeBlock?: ComponentProps<typeof TemplateBlock>['onChange'];
  selectedBlock?: api.local.TemplateBlock['id'];
  onSelectBlock?: ComponentProps<typeof TemplateBlock>['onSelect'];
  renderState?: RenderState;
  onError?: ComponentProps<typeof TemplateBlock>['onError'];
  isRender?: boolean;
};

export default function TemplatePaper({
  template,
  blocks,
  onChangeBlock,
  selectedBlock,
  onSelectBlock,
  renderState,
  onError,
  isRender,
  ...restProps
}: Props) {
  return (
    <Root
      $format={template.format}
      $orientation={template.orientation}
      {...restProps}
    >
      {blocks?.map((block) => (
        <TemplateBlock
          isSelected={block.id === selectedBlock}
          onChange={onChangeBlock}
          onSelect={onSelectBlock}
          renderState={renderState}
          block={block}
          onError={onError}
          key={block.id}
          isRender={isRender}
        />
      ))}
    </Root>
  );
}

const Root = styled(Paper)<{
  $format: TemplateFormat;
  $orientation: TemplateOrientation;
}>`
  position: relative;
  border-radius: 0;

  ${(props) => {
    const size = getPaperSize(props.$format, props.$orientation);
    return css`
      width: ${size.width}mm;
      height: ${size.height}mm;
    `;
  }}
`;
