import { conditionalFields } from '../constants/editor';

import * as types from './types';

export enum TemplateBlockType {
  BACKGROUND = 'background',
  TEXT = 'text',
  CONDITIONAL_TEXT = 'conditionalText',
}

export type CommonPayload = {
  zIndex: number;
};

export type BaseBlockType = {
  content: string;
  width: number;
  height: number;
  x: number;
  y: number;
};

export type BackgroundBlock = types.TemplateBlock<
  TemplateBlockType.BACKGROUND,
  CommonPayload & {
    url: string;
    color: string;
    size: number;
    position_x: number;
    position_y: number;
    opacity: number;
    blur: number;
    cover: boolean;
  }
>;
export type TextBlock = types.TemplateBlock<
  TemplateBlockType.TEXT,
  CommonPayload & BaseBlockType
>;

export type ConditionalBlock = types.TemplateBlock<
  TemplateBlockType.CONDITIONAL_TEXT,
  CommonPayload &
    BaseBlockType & {
      alt_content: string;
      conditional_field: keyof typeof conditionalFields;
    }
>;

export type TemplateBlock = BackgroundBlock | TextBlock | ConditionalBlock;
export type TemplateBlockInput =
  | types.TemplateBlockInput<BackgroundBlock>
  | types.TemplateBlockInput<TextBlock>
  | types.TemplateBlockInput<ConditionalBlock>;
