import { Box, Dialog, LinearProgress, Typography } from '@material-ui/core';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as api from 'app/api';
import { useDispatch, useSelector } from 'react-redux';
import { accessTokenSelector } from 'app/store/auth';
import { AppDispatch, RootState } from 'app/store';
import diplomasSlice from 'app/store/diplomas';

type Props = ComponentProps<typeof Dialog> & {
  task: api.types.DiplomasTask;
  pollInterval?: number;
  onPoll?: () => any;
};

const PROGRESS_INTERVAL = 5000;
export default function DiplomasGroupTaskDialog({
  task,
  pollInterval = 2000,
  onPoll,
}: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(accessTokenSelector)!;
  const [progress, setProgress] = useState({
    Current: 0,
    Total: 0,
  });
  const isDiplomasFetched = useSelector(
    (state: RootState) => state.diplomas.isDiplomasFetched
  );
  const status = useMemo(() => {
    switch (task.status) {
      case api.types.TaskStatus.CREATED:
        return 'задача создана';
      case api.types.TaskStatus.PENDING:
        return `в процессе`;
      case api.types.TaskStatus.COMPLETED:
        return !task.meta?.total
          ? 'не удалось найти достижения'
          : 'создаем дипломы';
      case api.types.TaskStatus.ERROR:
        return 'ошибка, перезапуск задачи...';
      case api.types.TaskStatus.FATAL:
        return 'критическая ошибка';
    }
  }, [task]);
  const pollPropgress = useCallback(
    (ignoreError = false) => {
      try {
        api.getDiplomasProgress(accessToken, task.id).then((res) => {
          if (res.data?.Total) {
            setProgress({ ...progress, ...res.data });

            if (res.data?.Total === res.data?.Current) {
              dispatch(diplomasSlice.actions.setIsDiplomasFetched(true));
            }
          }
        });
      } catch (err) {
        if (ignoreError) {
          console.log('error', err);
          return;
        }
        dispatch(diplomasSlice.actions.setIsDiplomasFetched(true));
      }
    },
    [accessToken, dispatch, progress, task.id]
  );

  const fatal = task.status === api.types.TaskStatus.FATAL;

  const finished =
    task.status === api.types.TaskStatus.FATAL ||
    task.status === api.types.TaskStatus.COMPLETED;

  const shouldPollingProgress =
    task.status === api.types.TaskStatus.COMPLETED &&
    task.meta?.total &&
    !isDiplomasFetched;

  const progreesVariant = fatal
    ? 'determinate'
    : progress.Total
    ? 'determinate'
    : 'indeterminate';

  const progressValue = fatal
    ? 100
    : progress.Total
    ? Math.round((progress.Current / progress.Total) * 100)
    : undefined;

  useEffect(() => {
    pollPropgress(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (finished) {
      return;
    }
    const timeout = setTimeout(onPoll, pollInterval);
    return () => clearTimeout(timeout);
  }, [finished, onPoll, pollInterval, task.status]);

  useEffect(() => {
    if (shouldPollingProgress) {
      const progressTimeout = setTimeout(pollPropgress, PROGRESS_INTERVAL);
      return () => clearTimeout(progressTimeout);
    }
  }, [pollPropgress, shouldPollingProgress, task.status, isDiplomasFetched]);
  return (
    <div>
      <div>
        <Box mb={2}>
          <Typography>Task ID: {task.id}</Typography>
          {!progress.Total ? (
            <Typography>Статус задачи: {status} </Typography>
          ) : null}
          {progress.Total ? (
            <Typography>
              Статус создания дипломов:{' '}
              {progress.Total ? `(${progress.Current}/${progress.Total})` : ''}
            </Typography>
          ) : null}
        </Box>
        <LinearProgress
          style={{ height: '40px' }}
          color="secondary"
          variant={progreesVariant}
          value={progressValue}
        />
      </div>
    </div>
  );
}
