import { useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'app/store';
import { accessTokenSelector, authCompleteThunk } from 'app/store/auth';
import { Routes } from 'app/router/Routes';

export default function AuthCompletePage() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const queryParams = useMemo(() => qs.parse(history.location.search), [
    history.location.search,
  ]);
  const token = useSelector(accessTokenSelector);
  const code = queryParams['code'];
  useEffect(() => {
    if (code) {
      dispatch(authCompleteThunk(code as string));
    }
  }, [code, dispatch]);
  return token ? <Redirect to={Routes.INDEX} /> : null;
}
