import {
  Box,
  FormControl,
  InputLabel,
  LinearProgress,
  List,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { DEBOUNCE_DEFAULT } from 'app/constants/debounce';
import Header from 'app/components/Header';
import Main from 'app/components/Main';
import RefreshableSubheader from 'app/components/RefreshableSubheader';
import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { Link, useHistory } from 'react-router-dom';
import qs from 'query-string';
import EventListItem from 'app/components/EventListItem';
import { accessTokenSelector } from 'app/store/auth';
import { compile } from 'path-to-regexp';
import { Routes } from 'app/router/Routes';
import { Pagination } from '@material-ui/lab';

import { fetchOrganizationsThunk } from '../store/organization';
import { useEvents } from '../hooks/useEvents';
import { ALL_ORGANIZATIONS_OPTION } from '../constants/organization';
import { useEventSearchStyles } from '../hooks/useEventSearchStyles';

const REQUEST_LIMIT = 10;
export default function EventsPage() {
  const classes = useEventSearchStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector(accessTokenSelector)!;
  const { getEvents, clearEvents, events: data, loading, count } = useEvents();
  const { data: user } = useSelector((state: RootState) => state.user);
  const {
    data: orgData,
    loading: orgLoading,
    adminOfOrganizations,
    status: orgFetchStatus,
  } = useSelector((state: RootState) => state.organizations);

  const queryParams = useMemo(() => qs.parse(history.location.search), [
    history.location.search,
  ]);
  const [page, setPage] = useState(() => {
    return Number(queryParams['page']) || 1;
  });
  const [search, setSearch] = useState(() => {
    return queryParams['search'] && String(queryParams['search']);
  });
  const [selectedOrganization, setSelectedOrganization] = useState(() => {
    return (queryParams['orgId'] && String(queryParams['orgId'])) || 'null';
  });
  const handleSearch = useMemo(
    () =>
      debounce((search: string) => {
        const pageNumber = 1;
        setPage(pageNumber);
        setSearch(search);
        history.push({
          ...history.location,
          search: qs.stringify({
            search,
            page: pageNumber || 1,
            ...(selectedOrganization && { orgId: selectedOrganization }),
          }),
        });
        if (!search && selectedOrganization === 'null') clearEvents();
      }, DEBOUNCE_DEFAULT),
    [history, selectedOrganization, clearEvents]
  );

  const pagesCount = useMemo(() => {
    if (!count) return 0;
    return Math.ceil(count / REQUEST_LIMIT);
  }, [count]);
  const handleSetPage = (event: ChangeEvent<unknown>, page: number) => {
    setPage(page);
    history.push({
      ...history.location,
      search: qs.stringify({
        page,
        ...(search && { search }),
        ...(selectedOrganization && { orgId: selectedOrganization }),
      }),
    });
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    const orgId = event.target.value as string;
    const pageNumber = 1;
    setSelectedOrganization(orgId);
    setPage(pageNumber);
    history.push({
      ...history.location,
      search: qs.stringify({
        page: pageNumber,
        ...(search && { search }),
        orgId,
      }),
    });

    if (!search && orgId === 'null') clearEvents();
  };

  const userId = user?.id;
  const organizations = orgData
    ? [ALL_ORGANIZATIONS_OPTION, ...orgData]
    : [ALL_ORGANIZATIONS_OPTION];

  useEffect(() => {
    const request = async () => {
      if (userId) {
        const searchString =
          (queryParams['search'] && String(queryParams['search'])) || '';
        const pageParam = Number(queryParams['page']) || 1;
        const organization =
          (queryParams['orgId'] && String(queryParams['orgId'])) || 'null';
        !orgData &&
          (await dispatch(
            fetchOrganizationsThunk({
              accessToken,
              user_id: userId,
            })
          ));

        if (!searchString && organization === 'null') return;
        if (orgFetchStatus === 'fetched') {
          const ids =
            searchString !== '' && !isNaN(Number(searchString)) && searchString;

          await getEvents(accessToken, {
            limit: REQUEST_LIMIT,
            offset: (pageParam - 1) * REQUEST_LIMIT,
            ...(searchString && !ids && { search: searchString }),
            ...(ids && { ids }),
            ordering: '-start, -updated_at',
            ...(organization !== 'null' && { organization }),
          });
        }
      }
    };

    request();
  }, [
    accessToken,
    dispatch,
    userId,
    queryParams,
    orgData,
    getEvents,
    orgFetchStatus,
  ]);

  const isLoading = loading || orgLoading;
  return (
    <>
      <Header />
      <Main>
        {isLoading && <LinearProgress />}
        <Box
          style={{
            display: 'grid',
            gap: '0',
            gridTemplateColumns: '2fr 1fr',
            alignItems: 'flex-end',
          }}
        >
          <TextField
            placeholder="Введите название мероприятия или его ID"
            variant="outlined"
            defaultValue={search}
            style={{ margin: '8px' }}
            onChange={(event) => handleSearch(event.target.value)}
          />
          <FormControl className={classes.formControl} variant="outlined">
            <InputLabel id="demo-simple-select-label">
              Выберите организацию
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              fullWidth
              value={orgData ? selectedOrganization : 'null'}
              label="Выберите организацию"
              onChange={handleChange}
              className={classes.selectEmpty}
              disabled={!orgData?.length}
            >
              {organizations.map((org) => (
                <MenuItem value={org.id} key={org.id + 'org'}>
                  {org.short_name || org.full_name || 'Организация'}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!!pagesCount && (
          <Box
            mt={2}
            ml="auto"
            mr="auto"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Pagination
              count={pagesCount}
              page={page}
              onChange={handleSetPage}
            />
          </Box>
        )}
        <List
          subheader={<RefreshableSubheader title="Мероприятия" count={count} />}
        >
          {data?.map((event) => (
            <EventListItem
              key={event.id}
              component={
                adminOfOrganizations[event?.organization?.id] ? Link : 'span'
              }
              to={{
                pathname: compile(Routes.EVENT)({ id: event.id }),
                search: `?${qs.stringify(queryParams)}`,
              }}
              button
              event={event}
              isUserAdmin={adminOfOrganizations[event?.organization?.id]}
              className={isLoading ? classes.loading : ''}
            />
          ))}
        </List>
        {!!pagesCount && (
          <Box
            mt={2}
            ml="auto"
            mr="auto"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Pagination
              count={pagesCount}
              page={page}
              onChange={handleSetPage}
            />
          </Box>
        )}
      </Main>
    </>
  );
}
