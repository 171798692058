import {
  CssBaseline,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { AxiosResponse } from 'axios';
import { unwrapResult } from '@reduxjs/toolkit';

import Router from './router/Router';
import { createStore } from './store';
import theme from './styles/theme';
import { $axios, getAuthHeaders } from './api';
import { Routes } from './router/Routes';
import GlobalStyle from './styles/GlobalStyle';
import { authRefreshThunk } from './store/auth';
import { FontsImport } from './editor/RichText';

export const history = createBrowserHistory();
export const { store, persistor } = createStore();

createAuthRefreshInterceptor($axios, (failed: AxiosResponse) => {
  const state = store.getState();
  return store
    .dispatch(authRefreshThunk(state.auth.token?.refresh_token!))
    .then(unwrapResult)
    .then((res) => {
      failed.config.headers = {
        ...failed.config.headers,
        ...getAuthHeaders(res.access_token),
      };
      return Promise.resolve();
    })
    .catch((error) => {
      console.error(error);
      history.push(Routes.LOGOUT);
      return Promise.reject();
    });
});

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyle />
              <FontsImport />
              <Router history={history} />
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
}
