import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import PaletteIcon from '@material-ui/icons/Palette';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopyOutlined';
import * as api from 'app/api';
import moment from 'moment';
import { ComponentProps } from 'react';

type Props = Omit<ListItemProps<any>, 'button'> & {
  button?: boolean;
  onDelete?: ComponentProps<typeof IconButton>['onClick'];
  onClone?: ComponentProps<typeof IconButton>['onClick'];
  template: api.types.Template;
};

export default function TemplateListItem({
  onDelete,
  onClone,
  template,
  ...restProps
}: Props) {
  return (
    <ListItem {...(restProps as any)}>
      <ListItemAvatar>
        <Avatar variant="square">
          <PaletteIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={template.title || 'Не указано'}
        secondary={moment(template.created_at).format('DD.MM.YYYY')}
      />
      {onDelete && (
        <ListItemSecondaryAction>
          <IconButton onClick={onClone}>
            <CopyIcon />
          </IconButton>
          <IconButton onClick={onDelete} edge="end">
            <DeleteIcon color="error" />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}
