import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from 'app/api';
import { types } from 'app/api';

type ownOrganizations = {
  [key: number]: true;
};

export type OrganizationState = {
  loading: boolean;
  data?: api.types.Organization[];
  adminOfOrganizations: ownOrganizations;
  status: 'idle' | 'fetched';
};

export const initialState: OrganizationState = {
  loading: false,
  adminOfOrganizations: {},
  status: 'idle',
};

export const fetchOrganizationsThunk = createAsyncThunk(
  'organizations/fetch',
  (
    args: { accessToken: string; user_id: types.User['id'] },
    { rejectWithValue }
  ) =>
    api
      .getUsersOrganizations(args.accessToken, args.user_id)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchOrganizationsThunk.fulfilled, (state, action) => {
        state.data = action.payload.results;
        state.adminOfOrganizations = action.payload.results.reduce(
          (acc, org): ownOrganizations => {
            acc[org.id] = true;
            return acc;
          },
          {} as ownOrganizations
        );
        state.loading = false;
        state.status = 'fetched';
      })
      .addCase(fetchOrganizationsThunk.pending, (state) => {
        state.loading = true;
      }),
});

export default organizationsSlice;
