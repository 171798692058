import { useCallback, useState } from 'react';

import * as api from '../api';

export const useEvents = () => {
  const [events, setEvents] = useState<api.types.Event[]>([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState<number | undefined>(undefined);

  const clearEvents = useCallback(() => {
    setEvents([]);
    setCount(undefined);
  }, []);

  const getEvents = useCallback(
    async (accessToken: string, params: api.types.EventRequestParams) => {
      try {
        setLoading(true);
        const { data } = await api.getEvents(accessToken, params);
        setEvents(data.results);
        setCount(data.count);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    },
    []
  );

  return { getEvents, events, loading, count, clearEvents };
};
