import * as api from 'app/api';
import { Rnd } from 'react-rnd';
import { useRef, useState } from 'react';
import useClickOutside from 'use-click-outside';
import ReactQuill from 'react-quill';
import { RenderState } from 'app/store/render';
import { RootState } from 'app/store';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';

import { conditionalFields } from '../constants/editor';

import { BaseBlockProps } from './TemplateBlock';
import RichText from './RichText';
import EditorViewport from './EditorViewport';

type Props = BaseBlockProps<api.local.ConditionalBlock> & {
  renderState?: RenderState;
};

export default function TextBlock({
  block,
  isSelected,
  onSelect,
  onChange,
  renderState,
  onError,
  isRender,
}: Props) {
  const templateState = useSelector((state: RootState) => state.template);
  const [readOnly, setReadOnly] = useState(true);
  const conditionalField = block.payload?.conditional_field || 'user.sex';
  const conditions = conditionalFields[conditionalField];
  const [conditionValue, setConditionValue] = useState(conditions[0].value);
  const editorRef = useRef<ReactQuill | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);
  useClickOutside(
    rootRef,
    (event: any) => {
      if (event.target.closest(EditorViewport.toString())) {
        if (!readOnly) {
          setReadOnly(true);
          return;
        }

        if (isSelected) {
          onSelect?.(undefined);
        }
      }
    },
    'mousedown'
  );
  return (
    <Rnd
      ref={(i) => (rootRef.current = i?.getSelfElement() as any)}
      size={{
        width: block.payload?.width as any,
        height: block.payload?.height as any,
      }}
      position={{
        x: block.payload?.x as any,
        y: block.payload?.y as any,
      }}
      disableDragging={!readOnly}
      onDragStop={(_, d) =>
        onChange?.({ ...block, payload: { ...block.payload, x: d.x, y: d.y } })
      }
      bounds="parent"
      style={{
        border: `1px dashed #000`,
        borderColor: isSelected ? undefined : 'transparent',
        zIndex: block.payload?.zIndex,
        position: 'relative',
      }}
      onResize={(e, dir, ref, delta, position) =>
        onChange?.({
          ...block,
          payload: {
            ...block.payload,
            width: ref.offsetWidth,
            height: ref.offsetHeight,
            ...position,
          },
        })
      }
      enableUserSelectHack
      onClick={() => !isSelected && onSelect?.(block.id)}
      onDoubleClick={() => {
        if (readOnly) {
          setReadOnly(false);
          setTimeout(() => editorRef.current?.focus(), 0);
        }
      }}
    >
      {block.type === 'conditionalText' && isSelected && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: '50%',
            display: 'flex',
            transform: 'translate(-50%, 70%)',
            zIndex: 10000,
          }}
        >
          {conditions &&
            conditions.map((condition, idx) => (
              <div key={condition.value + idx}>
                <Button
                  variant={
                    conditionValue === condition.value
                      ? 'contained'
                      : 'outlined'
                  }
                  color="primary"
                  size="small"
                  style={{
                    backgroundColor:
                      conditionValue === condition.value ? '' : '#fff',
                  }}
                  onClick={() => setConditionValue(condition.value)}
                >
                  {condition.title}
                </Button>
              </div>
            ))}
        </div>
      )}
      {(templateState.isTemplatePageReady || isRender) && (
        <>
          {conditions &&
            conditions.map(
              (cond, idx) =>
                conditionValue === cond.value && (
                  <RichText
                    key={idx + 'richText'}
                    id={block.id}
                    renderState={renderState}
                    editorRef={(instance) => (editorRef.current = instance)}
                    readOnly={readOnly}
                    value={
                      conditionValue === conditions[0].value
                        ? block.payload?.content
                        : block.payload?.alt_content
                    }
                    onChange={(content) => {
                      const contentVariant =
                        conditionValue === conditions[0].value
                          ? 'content'
                          : 'alt_content';
                      onChange?.({
                        ...block,
                        payload: {
                          ...block.payload,
                          [contentVariant]: content,
                        },
                      });
                    }}
                    showToolbar={isSelected}
                    onError={onError}
                    block={block}
                  />
                )
            )}
        </>
      )}
    </Rnd>
  );
}
