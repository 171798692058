import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import {
  ArrowBack,
  ArrowForward,
  Delete,
  Save,
  Settings,
} from '@material-ui/icons';
import { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import * as api from 'app/api';
import { compile } from 'path-to-regexp';
import { Routes } from 'app/router/Routes';

type Props = {
  backPath: string;
  template: api.types.Template;
  onEdit?: () => any;
  onDelete?: () => any;
  onSave?: () => any;
  loading?: boolean;
  onDiplomas?: () => any;
} & ComponentProps<typeof AppBar>;

export default function TemplateToolbar({
  backPath,
  template,
  onEdit,
  onDelete,
  onDiplomas,
  onSave,
  loading,
  ...restProps
}: Props) {
  return (
    <AppBar color="inherit" elevation={0} position="static" {...restProps}>
      <Toolbar variant="dense">
        <IconButton color="inherit" component={Link} to={backPath} edge="start">
          <ArrowBack />
        </IconButton>
        <Typography variant="h6">{template.title}</Typography>
        <Box mx={2}>
          <IconButton color="inherit" onClick={onEdit} size="small">
            <Settings />
          </IconButton>
          <IconButton color="inherit" onClick={onDelete} size="small">
            <Delete color="error" />
          </IconButton>
        </Box>
        {loading && (
          <CircularProgress
            color="secondary"
            size={24}
            variant="indeterminate"
          />
        )}
        <Box flex={1} />
        <Box clone mr={1}>
          <Button
            disabled={loading}
            onClick={onSave}
            color="primary"
            startIcon={<Save />}
          >
            Сохранить шаблон
          </Button>
        </Box>
        <Box clone mr={1}>
          <Button color="secondary" onClick={onDiplomas}>
            Создать дипломы
          </Button>
        </Box>
        <Box clone mr={1}>
          <Button
            component={Link}
            to={compile(Routes.TEMPLATE_DIPLOMAS)({ templateId: template.id })}
            variant="outlined"
            endIcon={<ArrowForward />}
          >
            Дипломы
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
