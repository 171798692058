import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'app/store';
import authSlice from 'app/store/auth';
import { useHistory } from 'react-router-dom';
import { Routes } from 'app/router/Routes';

export default function LogoutPage() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  useEffect(() => {
    dispatch(authSlice.actions.reset());
    history.replace(Routes.LOGIN);
  }, [dispatch, history]);
  return null;
}
