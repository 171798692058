import {
  AppBar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { ComponentProps, useMemo, useState } from 'react';
import { AddCircle } from '@material-ui/icons';
import * as api from 'app/api';
import { getBlockTypeOptions } from 'app/services/blocks';

import BlockItem from './BlockItem';
import BlockForm from './BlockForm';

type Props = ComponentProps<typeof Root> & {
  blocks?: api.local.TemplateBlock[];
  selectedBlockId?: api.local.TemplateBlock['id'];
  onSelectBlock?: (id: Props['selectedBlockId']) => any;
  onDeleteBlock?: (id: api.local.TemplateBlock['id']) => any;
  onChangeBlock?: (block: api.local.TemplateBlock) => any;
  onCreateBlock?: (type: api.local.TemplateBlockType) => any;
};

export default function BlocksPanel({
  blocks,
  selectedBlockId,
  onSelectBlock,
  onDeleteBlock,
  onChangeBlock,
  onCreateBlock,
  ...restProps
}: Props) {
  const blockOptions = useMemo(() => getBlockTypeOptions(), []);
  const [anchorEl, setAnchorEl] = useState<Element>();
  return (
    <Root {...restProps}>
      <AppBar elevation={0} color="default" position="static">
        <Toolbar variant="dense">
          <Typography variant="h6">Блоки</Typography>
          <Box flex={1} />
          <IconButton
            edge="end"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            color="secondary"
          >
            <AddCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        onClose={() => setAnchorEl(undefined)}
        open={!!anchorEl}
        anchorEl={anchorEl}
      >
        {blockOptions.map((option) => (
          <MenuItem
            onClick={() => {
              onCreateBlock?.(option.value);
              setAnchorEl(undefined);
            }}
            key={option.value}
          >
            {option.title}
          </MenuItem>
        ))}
      </Menu>
      {blocks?.map((block) => (
        <BlockItem
          key={block.id}
          block={block}
          isSelected={selectedBlockId === block.id}
          onSelect={() =>
            onSelectBlock?.(selectedBlockId === block.id ? undefined : block.id)
          }
          onDelete={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onDeleteBlock?.(block.id);
          }}
        >
          <BlockForm block={block} onChange={onChangeBlock} />
        </BlockItem>
      ))}
    </Root>
  );
}

const Root = styled(Paper)`
  border-radius: 0;
`;
