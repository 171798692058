import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import * as api from 'app/api';

import { RootState } from '.';

export type UserState = {
  data?: api.types.User;
};

export const initialState: UserState = {};

export const userSelector = createSelector(
  (state: RootState) => state.user,
  (userState) => userState.data
);

export const fetchUserThunk = createAsyncThunk(
  'user/fetch',
  (access_token: string, { rejectWithValue }) =>
    api
      .getUser(access_token)
      .then(({ data }) => data)
      .catch(rejectWithValue)
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder.addCase(fetchUserThunk.fulfilled, (state, action) => {
      state.data = action.payload;
    }),
});

export default userSlice;
